<template>
  <div v-if="payload" :style="styles" :key="render_key">
    <hotel-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      @submit="handle_submit"
      :styles="styles"
      :color="color"
      :design="design"
      :hotel="packages['hotel']"
      :product="products['hotel']"
      :node="node"
      v-if="product_combine === 'hotel'"
    />
    <flight-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      :styles="styles"
      @submit="handle_submit"
      :flight="packages['flight']"
      :product="products['flight']"
      :color="color"
      :design="design"
      :node="node"
      v-else-if="product_combine === 'flight'"
    />
    <bundle-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      :styles="styles"
      @submit="handle_submit"
      :color="color"
      :design="design"
      :bundle="packages['flight--hotel']"
      :product="products['flight']"
      :node="node"
      v-else-if="product_combine === 'flight--hotel'"
    />
    <tour-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      :styles="styles"
      @submit="handle_submit"
      :color="color"
      :design="design"
      :tour="packages['tour']"
      :product="products['tour']"
      :node="node"
      v-else-if="product_combine === 'tour'"
    />
    <transfer-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      :styles="styles"
      :color="color"
      :design="design"
      @submit="handle_submit"
      :transfer="packages['transfer']"
      :product="products['transfer']"
      :node="node"
      v-else-if="product_combine === 'transfer'"
    />
    <car-rental-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      :styles="styles"
      :color="color"
      :design="design"
      @submit="handle_submit"
      :car_rental="packages['car_rental']"
      :product="products['car_rental']"
      :node="node"
      v-else-if="product_combine === 'car_rental'"
    />
    <tour-package-form
      :flight_campaign="flight_campaign"
      :partner_id="partner_id"
      :default_destination="default_destination"
      :styles="styles"
      :color="color"
      :design="design"
      @submit="handle_submit"
      :tour_package="packages['tour_package']"
      :product="products['tour_package']"
      :node="node"
      v-else-if="product_combine === 'tour_package'"
    />
    <div v-else>
      <q-item-label class="text-center" caption>
        Not supported for this combination
      </q-item-label>
    </div>
  </div>
</template>
<script setup>
import { defineProps, onMounted, computed, ref } from 'vue'
import { stores } from 'src/stores'
import HotelForm from './hotel'
import FlightForm from './flight'
import BundleForm from './bundle'
import TourForm from './tour'
import TransferForm from './transfer'
import CarRentalForm from './car-rental'
import TourPackageForm from './tour-package'
import {
  check_cache_value,
  get_package_query,
  package_default_data
} from 'src/composables/utils/package-form'

const props = defineProps({
  styles: { type: Object },
  color: { type: String },
  packages: { type: Object },
  products: { type: Object },
  product_combine: { type: String },
  handle_submit: { type: Function },
  default_destination: { type: Object },
  flight_campaign: { type: String },
  partner_id: { type: String },
  design: { type: String },
  node: { type: Object }
})

const package_store = stores.use_package()
const payload = computed(() => package_store.payload?.[props.node?.id])
const render_key = ref(0)

onMounted(async () => {
  if (!payload.value) {
    package_store.payload[props.node.id] = package_default_data
  } else {
    check_cache_value(package_store.payload[props.node.id])
  }
  await get_package_query(package_store, props.node.id)
  render_key.value += 1
})
</script>
