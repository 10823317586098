<template>
  <div>
    <div class="egs-nav-bars-1" v-bind="bind">
      <q-toolbar class="q-pa-none">
        <div v-if="node.meta.title.enable" class="text-h4 q-mr-sm">
          {{ node.meta.title.value[context_store.language.code] }}
        </div>
        <template
          v-if="
            [DESKTOP_VIEWPORT, TABLET_VIEWPORT].includes(context_store.viewport)
          "
        >
          <div class="q-col-grow">
            <q-tabs no-caps align="center">
              <q-tab
                v-for="nav in node.meta.navigations"
                :key="nav.id"
                :name="nav.id"
              >
                <span class="text-weight-medium">
                  {{ nav.label[context_store.language.code] }}
                </span>
              </q-tab>
            </q-tabs>
          </div>
        </template>
        <template v-else>
          <q-space />
        </template>
        <template v-if="node.meta.right_drawer.enable">
          <q-btn
            round
            dense
            size="lg"
            @click="right_drawer_showed = true"
            flat
            :icon="node.meta.right_drawer.icon"
          ></q-btn>
        </template>
      </q-toolbar>
      <q-separator
        v-if="!node.meta.hidden_navigation_line"
        v-bind="separator_bind"
      />
    </div>
    <q-drawer
      v-model="right_drawer_showed"
      side="right"
      behavior="mobile"
      :width="350"
    >
      <q-list>
        <q-item v-for="nav in node.meta.navigations" clickable :key="nav.id">
          <q-item-section>
            <q-item-label>
              {{ nav.label[context_store.language.code] }}
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>
  </div>
</template>
<script setup>
import { computed, defineProps, ref } from 'vue'
import {
  DESKTOP_VIEWPORT,
  NODE_BASE_PROPS,
  TABLET_VIEWPORT
} from 'src/constants'
import { generate_common_node_styles } from 'src/composables/canvas'
import { stores } from 'src/stores'

const props = defineProps(NODE_BASE_PROPS)

const context_store = stores.use_context()

const right_drawer_showed = ref(false)

const styles = computed(() => {
  let result = generate_common_node_styles(props.node)

  result['color'] = props.node.meta.colors.text

  return result
})

const bind = computed(() => {
  let result = {
    style: styles.value
  }
  return result
})

const separator_bind = computed(() => {
  let result = {
    style: {
      'background-color': props.node.meta.colors.text
    }
  }
  return result
})
</script>
