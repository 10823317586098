<template>
  <q-field
    :model-value="modelValue"
    :label="label"
    color="black"
    hide-bottom-space
  >
    <template v-slot:control>
      <q-item-label lines="1">{{ display_value }}</q-item-label>
    </template>
    <template v-slot:append v-if="append_icon && !hidden_icon">
      <q-icon :name="append_icon" />
    </template>
    <template v-slot:prepend v-if="prepend_icon && !hidden_icon">
      <q-icon :name="prepend_icon" />
    </template>
    <q-popup-proxy
      ref="popup_proxy"
      v-if="!readonly && !disabled"
      transition-show="scale"
      transition-hide="scale"
    >
      <q-time
        @update:model-value="handle_date_changed"
        :model-value="modelValue"
        :style="{ '--q-primary': color }"
        format24h
        class="eg-time-picker"
      />
    </q-popup-proxy>
  </q-field>
</template>
<script setup>
import { change_datetime_format } from 'src/composables/utils'
import { defineEmits, defineProps, ref, computed, onMounted } from 'vue'
import { handle_page_scroll } from 'src/composables/utils'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: { type: String },
  prepend_icon: { type: String, default: 'schedule' },
  label: { type: String },
  append_icon: { type: String },
  readonly: { type: Boolean },
  disabled: { type: Boolean },
  color: { type: String, default: '#f5772f' },
  hidden_icon: { type: Boolean, default: false }
})

const popup_proxy = ref(null)

const handle_date_changed = (val) => {
  emit('update:modelValue', val)
  popup_proxy.value.hide()
}

const display_value = computed(() => {
  if (!props.modelValue) return null
  return change_datetime_format(
    `2000-01-01T${props.modelValue}:00`,
    '',
    'HH:mm'
  )
})

onMounted(() => {
  handle_page_scroll(() => {
    if (popup_proxy.value) {
      popup_proxy.value.hide()
    }
  })
})
</script>
<style>
.eg-time-picker .q-time__content {
  width: 300px;
  height: 300px;
}
.eg-time-picker {
  width: 300px;
}
.eg-time-picker .q-time__main {
  overflow: hidden !important;
}
</style>
