<template>
  <form-builder
    v-if="payload"
    :key="render_key"
    :fields="
      design === EXPEDIA_PACKAGE_FORM_DESIGN ? meta_expedia.fields : meta.fields
    "
    :node_id="node.id"
    :style="{ '--q-primary': color }"
    product_name="flight"
    @submit="submit"
  />
</template>
<script setup>
import { computed, defineEmits, onMounted, ref } from 'vue'
import key_by from 'lodash.keyby'
import clone_deep from 'lodash.clonedeep'
import { EXPEDIA_PACKAGE_FORM_DESIGN } from 'src/constants'
import { stores } from 'src/stores'

import FormBuilder from 'src/components/common/form-builder'
import { get_meta_default } from './meta_default'
import { get_meta_expedia } from './meta_expedia'

const props = defineProps({
  flight: { type: Object, required: true },
  product: { type: Object, required: true },
  styles: { type: Object },
  color: { type: String },
  flight_campaign: { type: String },
  partner_id: { type: String },
  default_destination: { type: Object },
  design: { type: String },
  node: { type: Object },
  setting: { type: Object },
  place_types: { type: Array, default: () => [] }
})

const context_store = stores.use_context()
const package_store = stores.use_package()
const payload = computed(
  () => package_store.payload[props.node?.id]?.['flight']
)
const render_key = ref(0)

onMounted(async () => {
  if (payload.value && props.default_destination) {
    payload.value.ar_airport = props.default_destination
  }
  const default_values = props.setting?.default_values ?? {}
  if (!default_values) return
  if (default_values?.dates) {
    payload.value.dates = default_values.dates
  }
  if (default_values?.fl_round_trip !== undefined) {
    payload.value.fl_round_trip = default_values.fl_round_trip
  }
  if (default_values?.travelers) {
    payload.value.travelers = default_values.travelers
  }
  if (default_values?.cabin_class) {
    payload.value.cabin_class = default_values.cabin_class
  }
  if (default_values?.ar_airport) {
    payload.value.ar_airport = default_values.ar_airport
  }
  if (default_values?.dp_airport) {
    payload.value.dp_airport = default_values.dp_airport
  }
  if (default_values?.fl_multi_city !== undefined) {
    payload.value.fl_multi_city = default_values.fl_multi_city
  }
  if (default_values?.cities) {
    payload.value.cities = default_values.cities
  }
  render_key.value += 1
})

const rules = {
  dp_airport: [(val) => !!val || ''],
  ar_airport: [(val) => !!val || ''],
  dates: [(val) => !!val || ''],
  place: [(val) => !!val || ''],
  cabin_class: [(val) => !!val || ''],
  travelers: []
}

const rules_multi_city = (index) => ({
  dp_airport: [(v) => validate_airport(v, index) || ''],
  ar_airport: [(v) => validate_airport(v, index) || ''],
  dates: [(v) => departure_date(v, index) || '']
})

const check_duplicate = (array, target_string) => {
  let count = 0
  for (let i = 0; i < array.length; i++) {
    if (array[i] === target_string) {
      count++
    }
    if (count >= 2) {
      return false
    }
  }
  return true
}

const validate_airport = (value, index) => {
  if (!value) return false
  const cities = payload.value.cities
  if (!cities || !cities?.length) return true
  const cities_code = cities.reduce((acc, city) => {
    if (city.dp_airport && city.ar_airport) {
      const key = city.dp_airport.code + '-' + city.ar_airport.code
      acc.push(key)
    }
    return acc
  }, [])
  const current_dp_ar_code =
    cities[index]?.dp_airport?.code + '-' + cities[index]?.ar_airport?.code
  return check_duplicate(cities_code, current_dp_ar_code)
}

const departure_date = (value, index) => {
  if (!value) return false
  const cities = payload.value.cities
  if (!cities || !cities?.length) return true
  const previous_date = cities[index - 1]?.dates
  if (!previous_date) return true
  return value > previous_date
}

const emit = defineEmits(['submit'])
const traveler_limits = key_by(props.flight.traveler_limits, 'traveler_type')

const handle_swap_airport = () => {
  const origin = clone_deep(payload.value.dp_airport)
  const destination = clone_deep(payload.value.ar_airport)
  payload.value.ar_airport = origin
  payload.value.dp_airport = destination
}

const handle_add_flight = () => {
  if (payload.value && payload.value.cities) {
    payload.value.cities = [
      ...payload.value.cities,
      {
        dp_airport: null,
        ar_airport: null,
        dates: null
      }
    ]
    render_key.value += 1
  }
}

const handle_remove_flight = (index) => {
  if (payload.value && payload.value.cities) {
    payload.value.cities = payload.value.cities.filter((city, i) => i !== index)
  }
  render_key.value += 1
}

const submit = () => {
  const {
    travelers,
    ar_airport,
    dp_airport,
    cabin_class,
    fl_round_trip,
    fl_multi_city,
    cities,
    dates
  } = payload.value || {}

  let data = {}

  if (fl_multi_city) {
    const citiesObj = cities.reduce((acc, city, index) => {
      if (city.dp_airport && city.ar_airport) {
        const key = city.dp_airport.code + '-' + city.ar_airport.code
        acc[key] = { departure_date: city.dates, index }
      }
      return acc
    }, {})

    data = {
      process: 'flight',
      currency_code: context_store.currency?.code,
      language_code: context_store.language?.code,
      package_id: props.flight.id,
      travelers: JSON.stringify(travelers),
      expectation: JSON.stringify({
        is_multi_city: true,
        fl_cabin_class: cabin_class,
        legs: citiesObj
      })
    }
  } else {
    let origin_type_dp_airport = 'airport_code',
      origin_type_ar_airport = 'airport_code'

    if (
      ['city', 'multi_city_vicinity', 'province_state'].includes(
        dp_airport?.type
      )
    ) {
      origin_type_dp_airport = 'city_code'
    }

    if (
      ['city', 'multi_city_vicinity', 'province_state'].includes(
        ar_airport?.type
      )
    ) {
      origin_type_ar_airport = 'city_code'
    }

    data = {
      process: 'flight',
      currency_code: context_store.currency?.code,
      language_code: context_store.language?.code,
      package_id: props.flight.id,
      travelers: JSON.stringify(travelers),
      expectation: JSON.stringify({
        is_multi_city: false,
        start_place_code: dp_airport?.code,
        start_place_type: origin_type_dp_airport,
        des_code: ar_airport?.code,
        des_type: origin_type_ar_airport,
        fl_cabin_class: cabin_class,
        fl_departure_date: fl_round_trip ? dates[0] : dates,
        fl_return_date: fl_round_trip ? dates[1] : dates,
        fl_round_trip: fl_round_trip
      })
    }
    if (props.flight_campaign) {
      data.flight_campaign = props.flight_campaign
    }
    if (props.partner_id) {
      data.partner_id = props.partner_id
    }
  }
  emit('submit', data)
}

const meta = get_meta_default({
  props,
  rules,
  payload,
  place_types: props.place_types,
  traveler_limits,
  handle_swap_airport,
  handle_remove_flight,
  handle_add_flight,
  viewport: context_store.viewport,
  rules_multi_city,
  show_multi_city: props.node.meta.show_multi_city
})

const meta_expedia = get_meta_expedia({
  props,
  rules,
  place_types: props.place_types,
  payload,
  traveler_limits,
  handle_swap_airport
})


</script>
