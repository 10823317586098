<template>
  <q-img
    class="egs-img"
    @click="handle_click"
    v-bind="img_bind"
    :src="url"
    fit="cover"
  >
    <template
      v-if="
        [
          PACKAGE_FORM_POPUP_EVENT_HANDLER.id,
          TRIPS_FORM_POPUP_EVENT_HANDLER.id
        ].includes(meta.events?.click?.handler)
      "
    >
      <q-dialog v-model="dialog_showed" persistent>
        <q-card style="max-width: 99vw; width: 1000px">
          <q-item>
            <q-item-section>
              <q-item-label
                v-if="meta.show_title || meta.show_caption"
                class="text-h6"
              >
                <span v-if="meta.show_title" v-html="meta.title?.[lang]" />
                <!-- <span v-if="meta.show_title && meta.show_caption">-</span> -->
                <!-- <span v-if="meta.show_caption">{{ meta.caption?.[lang] }}</span> -->
              </q-item-label>
              <q-item-label
                v-else-if="meta.events.click.destination"
                class="text-h6"
              >
                <span
                  v-if="
                    meta.events?.click?.handler ===
                    TRIPS_FORM_POPUP_EVENT_HANDLER.id
                  "
                >
                  Trips -
                </span>
                <span
                  class="text-capitalize"
                  v-else-if="meta.events.click.product_combine"
                >
                  {{ meta.events.click.product_combine }} -
                </span>
                <span>{{ meta.events.click.destination.name }}</span>
              </q-item-label>
              <q-item-label v-else class="text-h6">Search</q-item-label>
            </q-item-section>
            <q-item-section avatar>
              <q-btn flat round dense icon="close" v-close-popup />
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item
            v-if="
              meta.events?.click?.handler ===
              PACKAGE_FORM_POPUP_EVENT_HANDLER.id
            "
            class="full-width"
          >
            <package-form
              v-if="dialog_showed"
              :default_destination="meta.events.click.destination"
              class="full-width"
              :packages="packages"
              :node="node"
              :color="meta.events.click.package_form_color"
              :products="products"
              :handle_submit="handle_submit"
              :product_combine="meta.events.click.product_combine"
            />
          </q-item>
          <q-item
            v-if="
              meta.events?.click?.handler === TRIPS_FORM_POPUP_EVENT_HANDLER.id
            "
            class="full-width"
          >
            <trips-form
              v-if="dialog_showed"
              :default_destination="meta.events?.click?.destination"
              class="full-width"
              :node="node"
              :color="meta.events?.click?.package_form_color"
              @submit="handle_submit"
            />
          </q-item>
        </q-card>
      </q-dialog>
    </template>

    <div class="absolute-full flex q-column" v-if="has_cell_content">
      <q-space />
      <div
        v-if="meta.show_title"
        v-html="meta.title?.[lang]"
        class="text-subtitle1"
      ></div>
      <div v-if="meta.show_caption">
        <div v-html="meta.caption?.[lang]" class="text-caption"></div>
      </div>
    </div>
    <span v-bind="label_bind" v-if="meta.label.enable">
      <span v-html="meta.label?.value?.[lang]"></span>
    </span>
  </q-img>
</template>
<script setup>
import { computed, defineProps, ref } from 'vue'
import { NODE_BASE_PROPS } from 'src/constants'
import {
  generate_common_node_styles,
  generate_common_single_node_styles
} from 'src/composables/canvas'
import { stores } from 'src/stores'
import {
  EMBED_MODE,
  LINK_REDIRECT_EVENT_HANDLER,
  NO_ACTION_EVENT_HANDLER,
  PACKAGE_FORM_POPUP_EVENT_HANDLER,
  TRIPS_FORM_POPUP_EVENT_HANDLER,
  PAGE_REDIRECT_EVENT_HANDLER,
  PUBLISH_MODE
} from 'src/constants'
import keyBy from 'lodash.keyby'
import { change_page } from 'src/composables/editor'
import PackageForm from 'src/components/common/package-form'
import TripsForm from 'src/components/common/trip-form'
import { useRouter } from 'vue-router'

const props = defineProps(NODE_BASE_PROPS)
const router = useRouter()
const canvas_store = stores.use_canvas()
const context_store = stores.use_context()
const auth_store = stores.use_auth()

const meta = computed(() => props.node.meta)
const lang = computed(() => context_store.language.code)

const parent = computed(
  () => canvas_store.nodes[props.node.parent_id]
)
const has_cell_content = computed(() => {
  if (meta.value?.show_title) return true
  if (meta.value?.show_caption) return true
  return false
})

const styles = computed(() => {
  let style = generate_common_node_styles(props.node) || {}
  const events = meta.value?.events || {}
  if (events.click?.handler !== NO_ACTION_EVENT_HANDLER.id) {
    style['cursor'] = 'pointer'
  }
  return style
})

const url = computed(() => {
  if (meta.value?.file) {
    return meta.value.file.url
  }
  return null
})

const dialog_showed = ref(false)

const handle_click = () => {
  const click_event = meta.value.events?.click
  if (!click_event) return
  if (
    [
      PACKAGE_FORM_POPUP_EVENT_HANDLER.id,
      TRIPS_FORM_POPUP_EVENT_HANDLER.id
    ].includes(click_event.handler)
  ) {
    dialog_showed.value = true
  } else if (click_event.handler === PAGE_REDIRECT_EVENT_HANDLER.id) {
    const endpoint = click_event.page_endpoint
    if (context_store.mode === PUBLISH_MODE) {
      router.push({ path: endpoint })
    } else if (context_store.mode === EMBED_MODE) {
      window.location.href = endpoint
    } else {
      const page = canvas_store.pages.find((p) => p.endpoint === endpoint)
      if (!page) return
      change_page(page)
    }
  } else if (click_event.handler === LINK_REDIRECT_EVENT_HANDLER.id) {
    window.open(click_event.link, '_blank')
  }
}

const img_bind = computed(() => {
  let result = {
    style: styles.value
  }
  const ratio = props.node.meta.ratios[context_store.viewport]
  if (parent.value.layout.columns.enable) {
    result['width'] = '100%'
    if (ratio) {
      result['ratio'] = ratio
    }
  } else {
    result['width'] = `${props.node.dimension.width || 100}px`
    if (ratio) {
      result['ratio'] = ratio
    } else {
      result['height'] = `${props.node.dimension.height || 50}px`
    }
  }
  if (result.style['outline-width']) {
    result.style['border-width'] = result.style['outline-width']
    result.style['border-color'] = result.style['outline-color']
    result.style['border-style'] = result.style['outline-style']
    result.style['outline-width'] = 0
  }
  return result
})

const label_bind = computed(() => {
  const label_styles = props.node.meta.label.styles
  const background = props.node.meta.label.background
  const styles = generate_common_single_node_styles({
    styles: label_styles,
    background,
    css: {
      position: 'absolute',
      width: 'fit-content',
      height: 'fit-content'
    }
  })

  return { style: styles }
})

const handle_submit = (data) => {
  if (data) {
    if (auth_store.user.access_token) {
      data.access_token = auth_store.user.access_token
    }
    const booking_app_base_domain = context_store.app.booking_app_base_domain
    const qs = new URLSearchParams(data).toString()
    location.href = `https://${booking_app_base_domain}/shopping/processes/${data.process}?${qs}`
  }
}

const products = keyBy(context_store.products, 'code')
const packages = keyBy(
  context_store.packages.map((x) => ({
    ...x,
    product_codes: x?.product_codes?.join('--')
  })),
  'product_codes'
)
</script>
