<template>
  <q-form
    greedy
    class="q-pa-sm"
    v-if="payload"
    :key="JSON.stringify({ dates: payload.dates, key: render_key })"
    @submit="submit"
  >
    <div class="q-row q-col-gutter-sm">
      <div :class="className">
        <place-select
          has_code
          dense
          outlined
          hide-bottom-space
          fill-input
          hide-selected
          prepend_icon="place"
          destination_with_country
          v-model="payload.place"
          :key="JSON.stringify(payload.place)"
          :types="place_types"
          :rules="rules.place"
          :label="$t('common.destination')"
          disable_loadmore
          :hidden_icon="node.meta.hidden_icon"
        />
      </div>
      <div :class="className">
        <date-picker
          dense
          outlined
          hide-bottom-space
          :booking_cutoff_days="trip_traveler_config.booking_cutoff_days"
          :rules="rules.dates"
          v-model="payload.dates"
          :label="$t('common.departure')"
          :hidden_icon="node.meta.hidden_icon"
        />
      </div>
      <div :class="className">
        <traveler-select
          outlined
          dense
          hide-bottom-space
          with_rooms
          v-model="payload.travelers"
          :rules="rules.travelers"
          :max_adults="Number(traveler_limits?.['adult']?.limit ?? 9)"
          :max_infants="Number(traveler_limits?.['infant']?.limit ?? 3)"
          :max_children="Number(traveler_limits?.['child']?.limit ?? 6)"
          :traveler_limit_type="trip_traveler_config?.traveler_limit_type"
          :total_traveler_limit="trip_traveler_config?.total_traveler_limit"
          :traveler_types="product.traveler_types"
          :default_traveler_count="trip_traveler_config?.default_traveler_count"
          :label="$t('common.travelers')"
          :color="node.meta.color"
          :hidden_icon="node.meta.hidden_icon"
        />
      </div>
      <div :class="className">
        <search-btn :label="$t('common.search-now')" :color="node.meta.color" />
      </div>
    </div>
  </q-form>
</template>
<script setup>
import { computed, defineEmits, onMounted, ref } from 'vue'
import PlaceSelect from 'src/components/common/place-select/index.vue'
import DatePicker from 'src/components/common/date-picker/index.vue'
import TravelerSelect from 'src/components/common/traveler-select/index.vue'
import SearchBtn from './search-btn/index.vue'
import { stores } from 'src/stores'
import { DESKTOP_VIEWPORT } from 'src/constants'
import { get_package_query } from 'src/composables/utils/package-form'

const props = defineProps({
  styles: { type: Object },
  color: { type: String },
  flight_campaign: { type: String },
  partner_id: { type: String },
  default_destination: { type: Object },
  node: { type: Object }
})

const render_key = ref(0)

const package_store = stores.use_package()
const context_store = stores.use_context()
const payload = computed(() => package_store.trip_form[props.node?.id])

const className = computed(() => {
  if (context_store.viewport === DESKTOP_VIEWPORT) {
    return 'q-col-3'
  } else {
    return 'q-col-12'
  }
})

const emit = defineEmits(['submit'])

const traveler_limits = [
  {
    adult: {
      traveler_type: 'adult',
      limit: 9
    },
    child: {
      traveler_type: 'child',
      limit: 6
    },
    infant: {
      traveler_type: 'infant',
      limit: 6
    }
  }
]
const trip_traveler_config = {
  booking_cutoff_days: 1,
  total_traveler_limit: 9,
  traveler_limit_type: 'by_total'
}

const product = {
  traveler_types: ['adult', 'infant', 'child']
}

const place_types = computed(() => {
  return ['province_state', 'multi_city_vicinity', 'city', 'country']
})

const rules = {
  dates: [(val) => !!val || 'Required'],
  place: [(val) => !!val || 'Required'],
  travelers: []
}

const submit = () => {
  const { travelers, place, dates } = payload.value || {}

  emit('submit', {
    process: 'multileg_holiday',
    currency_code: context_store.currency?.code,
    language_code: context_store.language?.code,
    travelers: JSON.stringify(travelers),
    expectation: JSON.stringify({
      is_separate: false,
      mh_des_code: place?.id,
      mh_des_type: 'place_id',
      mh_start_date: dates
    })
    // flight_campaign: props.flight_campaign,
    // partner_id: props.partner_id,
  })
}
onMounted(async () => {
  if (!package_store.trip_form[props.node.id]) {
    package_store.trip_form[props.node.id] = {
      place: props.default_destination,
      dates: null,
      travelers: []
    }
  } else {
    if (props.default_destination) {
      package_store.trip_form[props.node.id].place = props.default_destination
    }
  }
  await get_package_query(package_store, props.node.id)
  render_key.value += 1
})
</script>
