import { createApp } from 'vue'
import { init_stores, stores } from 'src/stores'
import { CE_TAG, EMBED_MODE, CUSTOM_EVENTS } from 'src/constants'
import intersect from 'src/composables/utils/directives'

const resolve_props = (dom_el) => {
  const mode = dom_el.getAttribute('mode')
  if (!mode) throw 'Missing mode attribute for egs-cms element'

  const node_id = dom_el.getAttribute('node-id')
  const template_id = dom_el.getAttribute('template-id')
  const language_code = dom_el.getAttribute('language-code')
  const currency_code = dom_el.getAttribute('currency-code')
  const group = dom_el.getAttribute('group')
  const access_token = dom_el.getAttribute('access-token')
  const font_family = dom_el.getAttribute('font-family')
  const settings = dom_el.getAttribute('settings')
  const enable_submit_event = dom_el.getAttribute('enable-submit-event')

  let props = {
    access_token,
    group,
    currency_code,
    language_code,
    font_family,
    settings,
    enable_submit_event,
    mode
  }
  let context_id

  if (mode === EMBED_MODE) {
    context_id = `${node_id}_embed`
    props.node_id = node_id

    // override custom events
    let custom_events = {}

    for (const event_name of CUSTOM_EVENTS) {
      const custom_event_handlers = window.egs_custom_events || {}
      const event_handler = custom_event_handlers[event_name]
      if (!event_handler) continue
      custom_events[event_name] = event_handler
    }
    props.custom_events = custom_events
  } else {
    context_id = `${template_id}_edit`
    props.template_id = template_id
    props.mode = mode
  }
  props.context_id = context_id
  return props
}

const append_links = (links, container) => {
  links.forEach((link) => {
    const link_el = document.createElement('link')
    link_el.href = link.href
    link_el.rel = link.rel
    link_el.type = link.type
    container.appendChild(link_el)
  })
}

const append_font = (style, css, font_family) => {
  append_links(
    [
      {
        href: `https://fonts.googleapis.com/css?family=${font_family}:100,300,400,500,700,900`,
        rel: 'stylesheet',
        type: 'text/css'
      }
    ],
    document.head
  )
  style.textContent = `
      ${css}
      .egs-node-canvas *:not(i) {
        font-family: ${font_family} !important;
      }
    `
  const head_style = document.createElement('style')
  head_style.textContent = `
      .q-menu, .q-dialog {
          font-family: ${font_family} !important;
        }
      `
  document.head.appendChild(head_style)
}

export const create_app_as_custom_element = (component, { plugins, links }) => {
  if (links) {
    append_links(links, document.head)
  }

  if (component.styles) {
    component.styles.forEach((css) => {
      const style = document.createElement('style')
      style.textContent = css
      document.head.appendChild(style)
    })
  }

  class CustomElement extends HTMLElement {
    connectedCallback() {
      const props = resolve_props(this)
      const app = createApp(component, props)

      app.directive('intersect', intersect)
      const app_el = document.createElement('div')
      this.attachShadow({ mode: 'open' })

      if (component.styles) {
        component.styles.forEach((css) => {
          const style = document.createElement('style')
          if (props.font_family) {
            append_font(style, css, props.font_family)
          } else {
            style.textContent = css
          }
          this.shadowRoot.appendChild(style)
        })
      }

      if (links && links.length) {
        append_links(links, this.shadowRoot)
      }

      if (plugins) {
        plugins.forEach((p) => {
          app.use(p.plugin, p.options || {})
        })
      }

      init_stores(props.context_id, props.mode)

      app._context.context_id = props.context_id
      app.mount(app_el)

      this.shadowRoot.appendChild(app_el)
    }
  }

  if (!customElements.get(CE_TAG)) customElements.define(CE_TAG, CustomElement)
}

export const create_app_as_vue_component = (component, { plugins, links }) => {
  const dom_apps = document.querySelectorAll('div.egs-cms')

  if (dom_apps.length) {
    if (links && links.length) {
      append_links(links, document.head)
    }

    for (const dom_app of Array.from(dom_apps)) {
      const props = resolve_props(dom_app)

      const app = createApp(component, props)
      app.directive('intersect', intersect)

      app._context.context_id = props.context_id
      plugins.forEach((p) => {
        app.use(p.plugin, p.options || {})
      })

      init_stores(props.context_id, props.mode)

      app.mount(dom_app)
    }
  }
}

export default function (component, { plugins, links }) {
  const env_store = stores.use_env()
  env_store.$patch((state) => {
    state.CMS_URL = process.env.VUE_APP_CMS_URL
    state.IAM_URL = process.env.VUE_APP_IAM_URL
  })

  create_app_as_custom_element(component, {
    plugins,
    links
  })
  create_app_as_vue_component(component, { plugins, links })
}
