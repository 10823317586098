import { CACHE_VERSION } from 'src/constants'
import { reactive } from 'vue'

export default {
  id: 'package',
  setup: {
    state: () => {
      const payload = reactive({ version: CACHE_VERSION })
      const trip_form = reactive({ version: CACHE_VERSION })
      return {
        payload,
        trip_form
      }
    },
    persist: {
      afterRestore: (ctx) => {
        if (ctx.store?.payload?.version !== CACHE_VERSION) {
          ctx.store.$reset()
        }
      },
      paths: ['payload', 'trip_form']
    }
  }
}
