<template>
  <div class="q-row flex items-center">
    <q-btn
      :disable="!isNil(min) && modelValue <= min"
      round
      outline
      :style="{ color: color }"
      icon="remove"
      size="sm"
      @click="decrease"
    />
    <q-item-label class="text-subtitle1 text-center" :style="{ width: '40px' }">
      {{ modelValue }}
    </q-item-label>
    <q-btn
      :disable="!isNil(max) && modelValue >= max"
      round
      outline
      :style="{ color: color }"
      icon="add"
      size="sm"
      @click="increase"
    />
  </div>
</template>
<script setup>
import isNil from 'lodash/isNil'
const props = defineProps({
  modelValue: { type: Number, default: 0, required: true },
  min: { type: Number },
  max: { type: Number },
  color: { type: String, default: '#f5772f' }
})

const emit = defineEmits(['update:modelValue'])

const increase = () => {
  if (isNil(props.max) || props.modelValue < props.max) {
    emit('update:modelValue', props.modelValue + 1)
  }
}

const decrease = () => {
  if (isNil(props.min) || props.modelValue > props.min) {
    emit('update:modelValue', props.modelValue - 1)
  }
}
</script>
