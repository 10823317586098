<template>
  <q-item class="egs-destination-with-country" v-if="destination_with_country">
    <q-list class="egs-destination-with-country-list" dense>
      <q-item-label
        :class="['q-py-xs egs-destination-item', is_frist_item && 'q-pt-sm']"
        dense
        header
        @click="handle_select(option)"
      >
        <span>{{ option.name }}</span>
        <span>{{ option.trip_count }} Packages</span>
      </q-item-label>
      <div class="egs-destination-with-country-wrap q-ml-md q-pr-md">
        <q-item
          v-for="city in option.cities"
          :key="city.id"
          class="q-py-sm"
          dense
          clickable
          @click="handle_select(city)"
        >
          <q-item-section>
            <q-item-label>{{ city.name }}</q-item-label>
          </q-item-section>
          <q-item-section side>{{ city.trip_count }} Packages</q-item-section>
        </q-item>
      </div>
    </q-list>
  </q-item>
  <q-item
    v-else
    clickable
    :active="modelValue && modelValue.id === option.id"
    @click="handle_select(option)"
  >
    <q-item-section side>
      <q-icon v-if="option.type === 'airport'" name="flight" />
      <q-icon v-else-if="isNumber(option.star)" name="hotel" />
      <q-icon v-else name="place" />
    </q-item-section>
    <q-item-section>
      <q-item-label lines="1">{{ option.name }}</q-item-label>
      <q-item-label caption lines="1">
        {{ option.street_address || option.long_name }}
      </q-item-label>
    </q-item-section>
    <q-item-section side>
      <q-item-label v-if="isNumber(option.star)" lines="1">
        <q-rating
          v-model="option.star"
          max="5"
          size="1em"
          color="deep-orange-5"
          icon="star_border"
          icon-selected="star"
          icon-half="star_half"
          readonly
        />
      </q-item-label>
      <!-- <q-item-label v-else caption class="text-capitalize" lines="1">
        {{ option.type }}
      </q-item-label> -->
    </q-item-section>
  </q-item>
</template>

<script setup>
import isNumber from 'lodash/isNumber'

const props = defineProps({
  modelValue: { type: [Object, Array, String] },
  option: { type: Object },
  is_frist_item: { type: Boolean, default: false },
  destination_with_country: { type: Boolean, default: false }
})

const emit = defineEmits(['select'])

const handle_select = (option) => emit('select', option)
</script>
