<template>
  <div class="egs-node-canvas"
       v-bind="v_bind"
       ref="el">
    <template v-if="loaded">
      <background :model-value="canvas_store.page.background"
                  v-if="context_store.mode !== EMBED_MODE && canvas_store.page.background"
                  fixed/>
      <div>
        <node v-for="node in nodes"
              v-bind="node"
              :viewport="context_store.viewport"
              :key="node.id"/>
      </div>
    </template>
  </div>
</template>
<script setup>
import Node from 'src/components/nodes'
import {
  computed,
  defineProps,
  onMounted,
  ref,
} from 'vue'
import {stores} from 'src/stores'
import {
  NODE_TYPE_ATTRIBUTE,
  DESKTOP_VIEWPORT,
  PUBLISH_MODE,
  NODE_CANVAS_TYPE,
  MIN_DESKTOP_SCREEN_WIDTH,
  EDITOR_MINIMIZE_RATIO,
  EMBED_MODE,
  TABLET_VIEWPORT, MIN_TABLET_SCREEN_WIDTH
} from 'src/constants'
import Background from 'src/components/common/background'


const props = defineProps({
  nodes: {type: Array, required: true},
})

const loaded = ref(false)

const canvas_store = stores.use_canvas()
const context_store = stores.use_context()
const editor_store = stores.use_editor()

const el = ref(null)

const minimized = computed(() => {
  if (editor_store.adding_layouts.value) return true
  if (editor_store.adding_elements.value) return true
  return editor_store.setting_page.value
})

const styles = computed(() => {
  let result = {}

  if (![PUBLISH_MODE, EMBED_MODE].includes(context_store.mode)) {
    if (context_store.viewport === DESKTOP_VIEWPORT) {
      result['width'] = '100%'
      if (minimized.value) {
        result['transform'] = `scale(${EDITOR_MINIMIZE_RATIO})`
        result['transform-origin'] = '100% 0 0'
      }
    }
    else if (context_store.viewport === TABLET_VIEWPORT) {
      let buffer = 50
      result['width'] = `${MIN_DESKTOP_SCREEN_WIDTH + buffer}px`
      if (minimized.value) {
        result['transform'] = `scale(${EDITOR_MINIMIZE_RATIO})`
        result['transform-origin'] = '100% 0 0'
        result['margin-left'] = 'auto'
      }
      else {
        result['margin'] = 'auto'
      }
    }
    else {
      let buffer = 50
      result['width'] = `${MIN_TABLET_SCREEN_WIDTH + buffer}px`
      if (minimized.value) {
        result['margin-left'] = 'auto'
      }
      else {
        result['margin'] = 'auto'
      }
    }
  }

  return result
})

const classes = computed(() => {
  let result = []

  return result
})

const v_bind = computed(() => ({
  [NODE_TYPE_ATTRIBUTE]: NODE_CANVAS_TYPE,
  style: styles.value,
  class: classes.value
}))

onMounted(() => {
  canvas_store.$patch(state => {
    state.el = el.value
  })
  loaded.value = true
})

</script>
