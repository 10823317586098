import Api from 'src/composables/api'


export default class CmsService extends Api {

  load_context (payload) {
    return this.do_request(
      this.BASE_URL + '/context',
      {
        method: 'get',
        payload,
      }
    )
  }

  update_template (payload) {
    return this.do_request(
      this.BASE_URL + '/templates',
      {
        method: 'patch',
        payload,
      }
    )
  }


  get_page(payload) {
    return this.do_request(this.BASE_URL + '/pages', {
      method: 'get',
      payload
    })
  }

  list_pages (payload) {
    return this.do_request(
      this.BASE_URL + '/pages',
      {
        method: 'get',
        payload,
      }
    )
  }

  list_block_categories (payload) {
    return this.do_request(
      this.BASE_URL + '/nodes/blocks/categories',
      {
        method: 'get',
        payload,
      }
    )
  }

  list_node_blocks (payload) {
    return this.do_request(
      this.BASE_URL + '/nodes/blocks',
      {
        method: 'get',
        payload,
      }
    )
  }

  list_nodes (payload) {
    return this.do_request(
      this.BASE_URL + '/nodes',
      {
        method: 'get',
        payload,
      }
    )
  }

  init_nodes_from_block (payload) {
    return this.do_request(
      this.BASE_URL + '/nodes/init-from-block',
      {
        method: 'post',
        payload,
      }
    )
  }

  init_nodes_from_data (payload) {
    return this.do_request(
      this.BASE_URL + '/nodes/init-from-data',
      {
        method: 'post',
        payload,
      }
    )
  }

  update_page (payload) {
    return this.do_request(
      this.BASE_URL + '/pages',
      {
        method: 'patch',
        payload,
      }
    )
  }
  create_page (payload) {
    return this.do_request(
      this.BASE_URL + '/pages',
      {
        method: 'post',
        payload,
      }
    )
  }
  fetch_embed_code (payload) {
    return this.do_request(
      this.BASE_URL + '/embed-code',
      {
        method: 'get',
        payload,
      }
    )
  }

  publish (payload) {
    return this.do_request(
      this.BASE_URL + '/templates/publish',
      {
        method: 'post',
        payload,
      }
    )
  }
  load_versions (payload) {
    return this.do_request(
      this.BASE_URL + '/templates/publish/versions',
      {
        method: 'get',
        payload,
      }
    )
  }

  change_template_version (payload) {
    return this.do_request(
      this.BASE_URL + '/templates/change-version',
      {
        method: 'post',
        payload,
      }
    )
  }


  upload_file (payload) {
    return this.do_request(
      this.BASE_URL + '/files',
      {
        method: 'post',
        payload
      }
    )
  }

  delete_files (payload) {
    return this.do_request(
      this.BASE_URL + '/files',
      {
        method: 'delete',
        payload
      }
    )
  }

  list_files (payload) {
    return this.do_request(
      this.BASE_URL + '/files',
      {
        method: 'get',
        payload
      }
    )
  }

  list_directories (payload) {
    return this.do_request(
      this.BASE_URL + '/directories',
      {
        method: 'get',
        payload
      }
    )
  }

  delete_directories (payload) {
    return this.do_request(
      this.BASE_URL + '/directories',
      {
        method: 'delete',
        payload
      }
    )
  }

  create_directory (payload) {
    return this.do_request(
      this.BASE_URL + '/directories',
      {
        method: 'post',
        payload
      }
    )
  }

  update_directory (payload) {
    return this.do_request(
      this.BASE_URL + '/directories',
      {
        method: 'patch',
        payload
      }
    )
  }

  relocate_directories (payload) {
    return this.do_request(
      this.BASE_URL + '/directories/relocate',
      {
        method: 'patch',
        payload,
      }
    )
  }

  bulk_write(payload) {
    return this.do_request(
      this.BASE_URL + '/bulk-write',
      {
        method: 'post',
        payload
      }
    )
  }


}
