import qs from 'qs'
import { use_services } from 'src/composables/services'

const check_date_time = (dates) => {
  return dates
}

export const get_package_query = async (package_store, node_id) => {
  const services = use_services()
  const query_string = location.search.substring(1)
  if (!query_string) return

  const fetch_properties = async ({ search_text, place_id, codes, ids }) => {
    const response = await services.AppService.list_properties({
      search_text,
      language_code: 'en-US',
      place_id,
      per_page: 1,
      page: 1,
      codes,
      ids
    })
    return response.data?.result
  }

  const fetch_places = async ({
    search_text,
    types,
    per_page = 2,
    has_code = false,
    with_properties = false,
    property_included = false,
    codes,
    ids
  }) => {
    try {
      const response = await services.AppService.list_places({
        search_text: search_text,
        page: 1,
        per_page: per_page,
        types: types,
        language_code: 'en-US',
        has_code,
        with_properties,
        property_included,
        codes,
        ids
      })
      return response.data.places
    } catch (error) {
      console.error('Error fetching places:', error)
      return []
    }
  }

  const queryHandlers = {
    flight: async (query_data) => {
      if (
        query_data.dp_airport ||
        query_data.dp_airport_id ||
        query_data.dp_airport_code ||
        query_data.ar_airport ||
        query_data.ar_airport_id ||
        query_data.ar_airport_code
      ) {
        const [dp_airport, ar_airport] = await Promise.all([
          fetch_places({
            search_text: query_data.dp_airport,
            types: 'airport',
            per_page: 1,
            ids: query_data.dp_airport_id,
            codes: query_data.dp_airport_code?.toUpperCase(),
            has_code: !!query_data.dp_airport_code
          }),
          fetch_places({
            search_text: query_data.ar_airport,
            types: 'airport',
            per_page: 1,
            ids: query_data.ar_airport_id,
            codes: query_data.ar_airport_code?.toUpperCase(),
            has_code: !!query_data.ar_airport_code
          })
        ])
        return {
          travelers: [],
          cabin_class: query_data.cabin_class,
          dates: check_date_time(query_data.dates),
          dp_airport: dp_airport?.[0] ?? null,
          ar_airport: ar_airport?.[0] ?? null,
          fl_round_trip: query_data.fl_round_trip === 'true'
        }
      }
      return null
    },
    hotel: async (query_data) => {
      if (query_data.property_id) {
        const place = await fetch_properties({
          ids: query_data.property_id
        })
        return {
          place: place?.[0],
          travelers: [],
          dates: check_date_time(query_data.dates) || null
        }
      }
      if (query_data.place || query_data.place_code || query_data.place_id) {
        const place = await fetch_places({
          search_text: query_data.place,
          types: 'province_state,multi_city_vicinity,city',
          with_properties: true,
          per_page: 1,
          ids: query_data.place_id,
          codes: query_data.place_code?.toUpperCase(),
          has_code: !!query_data.place_code
        })

        return {
          place: place?.[0],
          travelers: [],
          dates: check_date_time(query_data.dates) || null
        }
      }

      return null
    },
    bundle: async (query_data) => {
      if (
        query_data.dp_airport ||
        query_data.dp_airport_id ||
        query_data.dp_airport_code ||
        query_data.ar_airport ||
        query_data.ar_airport_id ||
        query_data.ar_airport_code
      ) {
        const [dp_airport, ar_airport] = await Promise.all([
          fetch_places({
            search_text: query_data.dp_airport,
            types: 'airport',
            per_page: 1,
            ids: query_data.dp_airport_id,
            codes: query_data.dp_airport_code?.toUpperCase(),
            has_code: !!query_data.dp_airport_code
          }),
          fetch_places({
            search_text: query_data.ar_airport,
            types: 'airport',
            per_page: 1,
            ids: query_data.ar_airport_id,
            codes: query_data.ar_airport_code?.toUpperCase(),
            has_code: !!query_data.ar_airport_code
          })
        ])
        return {
          flight_dates: check_date_time(query_data.flight_dates) || [],
          hotel_dates: check_date_time(query_data.hotel_dates) || [],
          dp_airport: dp_airport?.[0] ?? null,
          ar_airport: ar_airport?.[0] ?? null,
          cabin_class: query_data.cabin_class,
          stars: +query_data.stars ?? null,
          travelers: [],
          form_data: {
            separate: query_data.accommodation_part === 'true'
          }
        }
      }
      return null
    },
    tour: async (query_data) => {
      if (query_data.place || query_data.place_code || query_data.place_id) {
        const place = (
          await fetch_places({
            search_text: query_data.place,
            codes: query_data.place_code?.toUpperCase(),
            ids: query_data.place_id,
            types: 'province_state,multi_city_vicinity,city'
          })
        )?.[0]
        return {
          place,
          travelers: [],
          dates: check_date_time(query_data.dates) || []
        }
      }
      return null
    },
    tour_package: async (query_data) => {
      if (query_data.place || query_data.place_id || query_data.place_code) {
        const place = (
          await fetch_places({
            search_text: query_data.place,
            types: 'province_state,multi_city_vicinity,city'
          })
        )?.[0]
        return {
          place,
          travelers: [],
          dates: check_date_time(query_data.dates) || []
        }
      }
      return null
    },
    car: async (query_data) => {
      if (
        query_data.pickup_location ||
        query_data.pickup_location_id ||
        query_data.pickup_location_code ||
        query_data.dropoff_data?.dropoff_location ||
        query_data.dropoff_data?.dropoff_location_id ||
        query_data.dropoff_data?.dropoff_location_code
      ) {
        const [pickup_location, dropoff_location] = await Promise.all([
          fetch_places({
            search_text: query_data.pickup_location,
            ids: query_data.pickup_location_id,
            codes: query_data.pickup_location_code?.toUpperCase(),
            has_code: !!query_data.pickup_location_code,
            types: 'airport'
          }),
          fetch_places({
            search_text: query_data.dropoff_data?.dropoff_location,
            id: query_data.dropoff_data?.dropoff_location_id,
            codes:
              query_data.dropoff_data?.dropoff_location_code?.toUpperCase(),
            has_code: !!query_data.dropoff_data?.dropoff_location_code,
            types: 'airport'
          })
        ])

        return {
          driver_age: query_data.driver_age ?? 30,
          pickup_location: pickup_location?.[0] ?? null,
          pickup_date: query_data.pickup_date,
          pickup_time: query_data.pickup_time,
          dropoff_data: {
            dropoff_location: dropoff_location?.[0] ?? null
          },
          diff_dropoff: query_data.diff_dropoff === 'true',
          dropoff_date: query_data.dropoff_date,
          dropoff_time: query_data.dropoff_time,
          travelers: []
        }
      }
      return null
    },
    transfer: async (query_data) => {
      if (
        query_data.airport ||
        query_data.airport_id ||
        query_data.airport_code ||
        query_data.destination ||
        query_data.destination_id
      ) {
        const airport = await fetch_places({
          search_text: query_data.airport,
          ids: query_data.airport_id,
          codes: query_data.airport_code?.toUpperCase(),
          has_code: !!query_data.airport_code,
          types: 'airport',
          per_page: 1
        })
        const destination = await fetch_properties({
          search_text: query_data.destination,
          ids: query_data.destination_id,
          place_id: airport[0].place_id
        })
        return {
          airport: airport[0],
          destination: destination[0],
          round_trip: query_data.diff_dropoff === 'true',
          pickup_date: query_data.pickup_date,
          pickup_time: query_data.pickup_time,
          return_date: query_data.return_date,
          return_time: query_data.return_time,
          travelers: []
        }
      }
      return null
    },
    trip: async (query_data) => {
      if (query_data.place || query_data.place_id || query_data.place_code) {
        const places = await fetch_places({
          search_text: query_data.place,
          ids: query_data.place_id,
          codes: query_data.place_code?.toUpperCase(),
          has_code: !!query_data.place_code,
          types: 'province_state,multi_city_vicinity,city,country',
          per_page: 1
        })
        return {
          place: places[0],
          dates: check_date_time(query_data.dates) || [],
          travelers: []
        }
      }
      return null
    }
  }

  if (query_string) {
    const query = transform_query(query_string)
    for (const [query_type, query_data] of Object.entries(query)) {
      if (query_type === 'trip') {
        package_store['trip_form'][node_id] = await queryHandlers.trip(
          query_data
        )
      }
      if (queryHandlers[query_type] && package_store.payload[node_id]) {
        const res = await queryHandlers[query_type](query_data)
        if (res) {
          package_store.payload[node_id][query_type] = res
        }
      }
    }
  }
}

export const transform_query = (query_string) => {
  const query = qs.parse(query_string)
  return {
    flight: {
      cabin_class: query.flight_cabin_class,
      dates: [query.flight_from_date, query.flight_to_date],
      dp_airport: query.flight_dp_airport,
      dp_airport_id: query.flight_dp_airport_id,
      dp_airport_code: query.flight_dp_airport_code,
      ar_airport: query.flight_ar_airport,
      ar_airport_id: query.flight_ar_airport_id,
      ar_airport_code: query.flight_ar_airport_code,
      fl_round_trip: query.flight_round_trip
    },
    hotel: {
      place: query.hotel_place,
      place_id: query.hotel_place_id,
      place_code: query.hotel_place_code,
      property_id: query.hotel_property_id,
      dates: [query.hotel_from_date, query.hotel_to_date]
    },
    bundle: {
      flight_dates: [
        query.bundle_flight_from_date,
        query.bundle_flight_to_date
      ],
      hotel_dates: [query.bundle_hotel_from_date, query.bundle_hotel_to_date],
      dp_airport: query.bundle_dp_airport,
      dp_airport_id: query.bundle_dp_airport_id,
      dp_airport_code: query.bundle_dp_airport_code,
      ar_airport: query.bundle_ar_airport,
      ar_airport_id: query.bundle_ar_airport_id,
      ar_airport_code: query.bundle_ar_airport_code,
      cabin_class: query.bundle_cabin_class,
      accommodation_part: query.bundle_accommodation_part,
      stars: query.bundle_stars
    },
    tour: {
      place: query.tour_place,
      place_id: query.tour_place_id,
      place_code: query.tour_place_code,
      dates: [query.tour_from_date, query.tour_to_date]
    },
    tour_package: {
      place: query.tour_package_place,
      place_id: query.tour_package_place_id,
      place_code: query.tour_package_place_code,
      dates: query.tour_package_date
    },
    car: {
      pickup_location: query.car_pickup_location,
      pickup_location_id: query.car_pickup_location_id,
      pickup_location_code: query.car_pickup_location_code,
      dropoff_data: {
        dropoff_location: query.car_dropoff_location,
        dropoff_location_id: query.car_dropoff_location_id,
        dropoff_location_code: query.car_dropoff_location_code
      },
      driver_age: query.car_driver_age,
      pickup_date: query.car_pickup_date,
      pickup_time: query.car_pickup_time,
      diff_dropoff: query.car_diff_dropoff,
      dropoff_date: query.car_dropoff_date,
      dropoff_time: query.car_dropoff_time
    },
    transfer: {
      airport: query.transfer_airport,
      airport_id: query.transfer_airport_id,
      airport_code: query.transfer_airport_code,
      destination: query.transfer_destination,
      destination_id: query.transfer_destination_id,
      diff_dropoff: query.transfer_diff_dropoff,
      pickup_date: query.transfer_pickup_date,
      pickup_time: query.transfer_pickup_time,
      return_date: query.transfer_return_date,
      return_time: query.transfer_return_time
    },
    trip: {
      place: query.trip_place,
      place_id: query.trip_place_id,
      place_code: query.trip_place_code,
      dates: query.trip_date
    }
  }
}

const check_travelers_value = (travelers) => {
  if (!travelers?.length) return []
  travelers.forEach((traveler) => {
    if (
      !('type' in traveler) ||
      !traveler.type ||
      !['adult', 'child', 'infant'].includes(traveler.type)
    ) {
      traveler.type = 'adult'
    }
    if (!('age' in traveler) || !traveler.age || !(Number(traveler.age) > 0)) {
      traveler.age = 18
    }
    if (
      !('room' in traveler) ||
      !traveler.room ||
      !(Number(traveler.room) > 0)
    ) {
      traveler.room = 1
    }
    if (typeof traveler.room === 'string') traveler.room = Number(traveler.room)
    if (typeof traveler.age === 'string') traveler.age = Number(traveler.age)
  })
  return travelers
}

export const check_cache_value = (cache) => {
  Object.values(cache).forEach((property) => {
    if (property && Array.isArray(property.travelers)) {
      check_travelers_value(property.travelers)
    }
  })
}

export const package_default_data = {
  flight: {
    travelers: [],
    cabin_class: null,
    dates: null,
    dp_airport: null,
    ar_airport: null,
    form: null,
    fl_round_trip: true,
    fl_multi_city: false,
    cities: [
      {
        dp_airport: null,
        ar_airport: null,
        dates: null
      },
      {
        dp_airport: null,
        ar_airport: null,
        dates: null
      }
    ]
  },
  hotel: {
    place: null,
    travelers: [],
    dates: null
  },
  bundle: {
    flight_dates: null,
    hotel_dates: [],
    travelers: [],
    dp_airport: null,
    ar_airport: null,
    cabin_class: null,
    stars: null,
    form_data: {
      separate: false
    }
  },
  tour: {
    place: null,
    travelers: [],
    dates: null
  },
  transfer: {
    airport: null,
    destination: null,
    from_airport: true,
    round_trip: true,
    pickup_date: null,
    pickup_time: null,
    return_date: null,
    return_time: null,
    travelers: []
  },
  car: {
    driver_age: 30,
    pickup_location: null,
    pickup_date: null,
    pickup_time: null,
    dropoff_data: {
      dropoff_location: null
    },
    diff_dropoff: null,
    dropoff_date: null,
    dropoff_time: null
  },
  tour_package: {
    place: null,
    travelers: [],
    dates: null
  },
  train: {
    travelers: [],
    cabin_class: null,
    dates: null,
    dp_airport: null,
    ar_airport: null,
    form: null,
    tn_round_trip: true
  },
  trip: {
    place: null,
    travelers: [],
    dates: null
  },
  train_hotel: {
    flight_dates: null,
    hotel_dates: [],
    travelers: [],
    dp_airport: null,
    ar_airport: null,
    cabin_class: null,
    stars: null,
    form_data: {
      separate: false
    }
  }
}
