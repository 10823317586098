<template>
  <q-select ref="select" v-bind="v_bind" @update:model-value="handle_changes">
    <template v-slot:append v-if="append_icon && !hidden_icon">
      <q-icon :name="append_icon" />
    </template>
    <template v-slot:prepend v-if="prepend_icon && !hidden_icon">
      <q-icon :name="prepend_icon" />
    </template>
    <template v-slot:option="scope">
      <q-item v-bind="scope.itemProps">
        <q-item-section>
          <!--eslint-disable vue/no-v-text-v-html-on-component-->
          <q-item-label class="text-capitalize" v-html="$t(scope.opt.label)" />
        </q-item-section>
      </q-item>
    </template>
    <template v-slot:selected v-if="selected_option">
      <q-item-label :lines="1">
        <span :dom-key="selected_option.label">
          {{ $t(selected_option.label) }}
        </span>
      </q-item-label>
    </template>
  </q-select>
</template>
<script setup>
import { defineProps, ref, computed, onMounted, defineEmits } from 'vue'
import { handle_page_scroll } from 'src/composables/utils'

const props = defineProps({
  modelValue: { type: String },
  label: { type: String },
  cabin_classes: { type: Array, default: () => [] },
  append_icon: { type: String },
  prepend_icon: { type: String },
  hide_label_mode: { type: Boolean },
  hidden_icon: { type: Boolean, default: false }
})
const emit = defineEmits(['update:modelValue'])
const select = ref(null)

const options = ref([
  {
    id: 'Economy',
    label: 'components.shopping.forms.common.cabin-select.options.economy'
  },
  {
    id: 'Premium Economy',
    label: 'components.shopping.forms.common.cabin-select.options.premium'
  },
  {
    id: 'Business',
    label: 'components.shopping.forms.common.cabin-select.options.business'
  },
  {
    id: 'First',
    label: 'components.shopping.forms.common.cabin-select.options.first'
  }
])

const v_bind = computed(() => {
  const result = {
    'model-value': props.modelValue,
    color: 'black',
    options: options.value,
    'option-label': 'label',
    'option-value': 'id'
  }

  if (props.hide_label_mode) {
    result.class = 'expedia-cabin-class-select'
  }

  const hide_label_condition = props.hide_label_mode
    ? !!props.modelValue
    : false
  if (props.label && !hide_label_condition) {
    result.label = props.label
  }

  return result
})

const selected_option = computed(() => {
  return options.value.find((o) => o.id === props.modelValue)
})

onMounted(() => {
  if (props.cabin_classes?.length > 0) {
    let result = []

    options.value.forEach((opt) => {
      if (props.cabin_classes.find((i) => i === opt.id)) {
        result.push(opt)
      }
    })

    options.value = result
    const data = result?.map((r) => r?.id)
    if (!data?.includes(props.modelValue)) {
      handle_changes(data?.[0])
    }
  }
  handle_page_scroll(() => {
    if (select.value) {
      select.value.hidePopup()
    }
  })
})

const handle_changes = (val) => {
  emit('update:modelValue', val)
}
</script>

<style lang="scss">
.expedia-cabin-class-select {
  min-width: 140px;

  .q-field__control-container {
    padding-top: 0 !important;
  }

  .q-field__label {
    top: 6px !important;
    transform: none !important;
  }

  .q-field__control,
  .q-field__native {
    min-height: 32px !important;
  }
  .q-field__marginal {
    height: 32px !important;
  }
}
</style>
