<template>
  <package-form :flight_campaign="flight_campaign"
                :partner_id="partner_id"
                :styles="styles"
                :packages="packages"
                :color="color"
                :products="products"
                :design="design"
                :handle_submit="handle_submit"
                :product_combine="product_combine"
                :node="props.node"
                />
</template>
<script setup>
import { computed, defineProps, nextTick, watch } from 'vue'
import { stores } from 'src/stores'
import keyBy from 'lodash.keyby'
import { NODE_BASE_PROPS } from 'src/constants'
import PackageForm from "src/components/common/package-form";
import { DEFAULT_PACKAGE_FORM_DESIGN } from 'src/constants'
import { generate_common_node_styles } from 'src/composables/canvas'
import { useRoute } from 'vue-router'

const route = useRoute()
const props = defineProps(NODE_BASE_PROPS)
const context_store = stores.use_context()
const auth_store = stores.use_auth()
const packages = keyBy(context_store.packages.map(x => ({
  ...x,
  product_codes: x?.product_codes?.join('--')
})), 'product_codes')


const products = keyBy(context_store.products, 'code')

const product_combine = computed(() => {
  const node = props.node
  if (!node) return ''
  const product_codes = node.meta.product_codes.filter(pc => pc !== null)
  product_codes.sort()
  return product_codes.join('--')
})

const styles = computed(() => {
  return generate_common_node_styles(props.node)
})


const color = computed(() => {
  const meta = props.node.meta
  let result = '#1976d2'

  if (meta.color) result = meta.color

  return result
})

const design = computed(() => {
  const meta = props.node.meta
  return meta?.design || DEFAULT_PACKAGE_FORM_DESIGN
})

const flight_campaign = computed(() => route.query?.flight_campaign || '')

const partner_id = computed(() => route.query?.partner_id || route.query?.sourceId || '')

const handle_submit = (data) => {
  if (data) {
    if (auth_store.user.access_token) {
      data.access_token = auth_store.user.access_token
    }
    const booking_app_base_domain = context_store.app.booking_app_base_domain
    const qs = new URLSearchParams(data).toString()
    if(data.process === 'hotel' && data.place_type === 'hotel') {
      location.href = `https://${booking_app_base_domain}/shopping/products/hotel/${data.place_id}?${qs}&scope_type=${context_store?.group}`
      return;
    }
    location.href = `https://${booking_app_base_domain}/shopping/processes/${data.process}?${qs}&scope_type=${context_store?.group}`
  }
}

watch(
  () => product_combine.value,
  async () => {
    await nextTick()
  }
)
</script>
