<template>
  <div :style="styles" :class="classes">
    <div ref="content_box" v-html="content"></div>
  </div>
</template>
<script setup>
import { computed, defineProps, nextTick, ref, watch } from 'vue'
import { NODE_BASE_PROPS } from 'src/constants'
import { stores } from 'src/stores'
import { generate_common_node_styles } from 'src/composables/canvas'

const props = defineProps(NODE_BASE_PROPS)

const content_box = ref(null)

const context_store = stores.use_context()
const canvas_store = stores.use_canvas()

const styles = computed(() => {
  let result = generate_common_node_styles(props.node)
  result['word-wrap'] = 'break-word'
  return result
})

const classes = computed(() => {
  let result = ['egs-text']
  return result
})

const content = computed(() => {
  const node = props.node
  if (!node) return ''
  let result = node.meta.content[context_store.language.code]
  if (!result) {
    result = `<< This node does not have content for ${context_store.language.code} language >>`
  }
  return result
})
</script>
