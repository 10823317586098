<template>
  <q-card v-bind="bind">
    <q-form
      v-model="valid_values"
      greedy
      ref="form_el"
      style="width: 100%; height: 100%"
      @submit="submit"
    >
      <slot></slot>
    </q-form>
    <template v-if="dialog?.enable">
      <q-dialog
        v-model="dialog_showed"
        @before-hide="trigger_redirect"
        ref="dialog_el"
        persistent
      >
        <q-card
          style="max-width: 99vw; width: 400px"
          class="bg-positive"
        >
          <q-card-actions align="right" class="bg-white">
            <div class="text-center q-pt-lg" >
              <div style="width: 70px; margin: 0 auto;">
                <img
                  class="full-width"
                  src="https://cdn-icons-png.flaticon.com/512/5709/5709755.png"
                />
              </div>
              <h5>{{ dialog.title[context_store.language.code] }}</h5>
              <br />
              <p style="color: gray">
                {{ dialog.message[context_store.language.code]?.replace(/{app_name}/g, context_store?.app?.name) }}
              </p>
            </div>
            <q-btn flat label="OK" v-close-popup color="primary" />
          </q-card-actions>
        </q-card>
      </q-dialog>
    </template>
  </q-card>
</template>
<script setup>
import { computed, defineProps, ref, toRef } from 'vue'
import { useI18n } from 'vue-i18n'
import {
  AGENT_REGISTRATION_FORM_TYPE,
  B2B_DASHBOARD_REDIRECT_HANDLER,
  EMBED_MODE,
  LINK_REDIRECT_EVENT_HANDLER,
  LOGIN_FORM_TYPE,
  NODE_BASE_PROPS,
  PAGE_REDIRECT_EVENT_HANDLER,
  PUBLISH_MODE
} from 'src/constants'
import { generate_common_node_styles } from 'src/composables/canvas'
import { stores } from 'src/stores'
import { use_services } from 'src/composables/services'
import { authenticate } from 'src/composables/auth'
import { useRouter } from 'vue-router'

const props = defineProps(NODE_BASE_PROPS)
const { t } = useI18n()
const context_store = stores.use_context()
const canvas_store = stores.use_canvas()
const auth_store = stores.use_auth()

const router = useRouter()
const services = use_services()

const dialog_showed = ref(null)

const form_el = ref(null)
const dialog_el = ref(null)

const open_dialog = () => {
  dialog_showed.value = true
}

const styles = computed(() => {
  return generate_common_node_styles(props.node)
})

const valid_values = ref(false)

const dialog = computed(() => {
  return props.node.meta.dialog
})

const form_type = computed(() => {
  return props.node.meta.type
})

const response_data = ref(null)

const submit = () => {
  canvas_store.submitting_forms[props.node.id] = {
    in_progress: false,
    error: null,
    message: null
  }
  const handler = submit_handlers[form_type.value]
  return handler()
}

const handle_after_submit = () => {
  if (dialog.value?.enable) open_dialog()
  else trigger_redirect()
}

const trigger_redirect = () => {
  const settings = props.node.meta.after_submit
  if (settings.handler === PAGE_REDIRECT_EVENT_HANDLER.id) {
    if (context_store.mode === PUBLISH_MODE) {
      router.push({ path: settings.page_endpoint })
    } else if (context_store.mode === EMBED_MODE) {
      window.location.href = settings.page_endpoint
    } else {
      canvas_store.$patch((state) => {
        state.page = state.pages.find(
          (p) => p.endpoint === settings.page_endpoint
        )
      })
    }
  } else if (settings.handler === LINK_REDIRECT_EVENT_HANDLER.id) {
    window.location.href = settings.link
  } else if (settings.handler === B2B_DASHBOARD_REDIRECT_HANDLER.id) {
    window.location.href = `https://${context_store.app.booking_app_base_domain}/b2b-auth/callback?access_token=${auth_store.user.access_token}`
  }
}

const login_submit = async () => {
  if (!(await form_el.value.validate())) return
  const process = toRef(canvas_store.submitting_forms, props.node.id)
  const node = canvas_store.nodes[props.node.id]
  process.value.in_progress = true

  let payload = {
    email: node.meta.values.email,
    password: node.meta.values.password
  }
  const response = await authenticate(payload)
  if (response.status === 200) {
    response_data.value = response.data
    handle_after_submit()
  } else {
    process.value.error = response.data.error
    process.value.message = response.data.message ?? 'Something went wrong'
  }
  process.value.in_progress = false
}
const agent_registration_submit = async () => {
  if (!(await form_el.value.validate())) return
  const process = toRef(canvas_store.submitting_forms, props.node.id)

  process.value.in_progress = true

  const service = services.AppService

  service.set_domain(context_store.app.booking_app_base_domain)

  const node = canvas_store.nodes[props.node.id]

  const response = await service.register_b2b_agent(node.meta.values)
  if (response.status === 200) {
    response_data.value = response.data
    handle_after_submit()
  } else {
    process.value.error = response.data.error
    process.value.message = response.data.message ?? 'Something went wrong'
  }
  process.value.in_progress = false
}

const bind = computed(() => {
  const meta = props.node.meta

  let result = {
    style: styles.value
  }

  if (meta.bordered) {
    result['bordered'] = true
  }

  if (meta.flat) {
    result['flat'] = true
  }

  return result
})

const submit_handlers = {
  [LOGIN_FORM_TYPE]: login_submit,
  [AGENT_REGISTRATION_FORM_TYPE]: agent_registration_submit
}
</script>
