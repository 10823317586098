<template>
  <div v-bind="bind">
    <q-item clickable no-caps>
      <template v-if="auth_store.user.access_token">
        <q-item-section
          :avatar="!!has_name"
          v-if="has_avatar"
          style="min-width: 0px"
        >
          <q-avatar color="negative" text-color="white" size="md">
            {{ username?.[0].toUpperCase() }}
          </q-avatar>
        </q-item-section>
        <q-item-section v-if="has_name">
          <q-item-label lines="1" class="text-subtitle1">
            {{ username }}
          </q-item-label>
        </q-item-section>
        <q-item-section
          avatar
          v-if="has_dropdown_icon"
          style="padding-left: 0; min-width: 0"
        >
          <q-icon name="arrow_drop_down" size="sm" />
        </q-item-section>
        <q-menu fit @before-show="trigger_menu_before_show">
          <q-list style="min-width: 250px">
            <!--          <q-item clickable v-close-popup-->
            <!--                  :to="{name: page.id}"-->
            <!--                  v-for="page in pages"-->
            <!--                  :key="page.id">-->
            <!--            <q-item-section>-->
            <!--              <q-item-label>{{ page.name }}</q-item-label>-->
            <!--            </q-item-section>-->
            <!--          </q-item>-->
            <template v-if="canvas_store.page.group === 'B2B'">
              <q-item>
                <q-item-section side>
                  <q-icon name="account_balance_wallet" />
                </q-item-section>
                <q-item-section>
                  <q-item-label lines="1" class="text-weight-bold">
                    <template v-if="loading_balance">
                      <q-skeleton type="text" />
                    </template>
                    <template v-else>
                      <template v-if="balance">
                        {{ balance.currency_code }}
                        {{ total_display({}, balance.amount) }}
                      </template>
                      <template v-else>-/-</template>
                    </template>
                  </q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="redirect">
                <q-item-section side>
                  <q-icon name="dashboard" />
                </q-item-section>
                <q-item-section>
                  <q-item-label>Dashboard</q-item-label>
                </q-item-section>
              </q-item>
              <q-separator />
            </template>
            <q-item clickable v-close-popup @click="logout">
              <q-item-section side>
                <q-icon name="logout" />
              </q-item-section>
              <q-item-section>
                <q-item-label>
                  {{
                    meta.after_login.logout_label[context_store.language.code]
                  }}
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </template>
      <template v-else>
        <q-item-section>
          <q-item-label class="text-subtitle1 text-weight-bold text-center">
            {{ meta.before_login.label[context_store.language.code] }}
          </q-item-label>
        </q-item-section>
      </template>
    </q-item>
  </div>
</template>
<script setup>
import { computed, defineProps, ref } from 'vue'
import {
  EMBED_MODE,
  ERROR_PAGE_TYPE,
  LINK_REDIRECT_EVENT_HANDLER,
  NO_ACTION_EVENT_HANDLER,
  NODE_BASE_PROPS,
  PAGE_REDIRECT_EVENT_HANDLER,
  PUBLISH_MODE
} from 'src/constants'
import { generate_common_node_styles } from 'src/composables/canvas'
import { stores } from 'src/stores'
import {
  AVATAR_DESIGN,
  FULL_DESIGN,
  NAME_DESIGN
} from 'src/components/nodes/builtins/login-bars/constants'
import { useRouter } from 'vue-router'
import { use_services } from 'src/composables/services'
import { change_page } from 'src/composables/editor'
import { get_base_domain, total_display } from 'src/composables/utils'
import { Cookies } from 'quasar'

const props = defineProps(NODE_BASE_PROPS)

const services = use_services()
const context_store = stores.use_context()
const canvas_store = stores.use_canvas()
const auth_store = stores.use_auth()

const router = useRouter()

const loading = ref(false)

const meta = computed(() => props.node.meta)

const pages = computed(() =>
  canvas_store.pages.filter(
    (p) => p.type !== ERROR_PAGE_TYPE && p.group === canvas_store.page.group
  )
)

const has_avatar = computed(() => {
  return [AVATAR_DESIGN, FULL_DESIGN].includes(meta.value.design)
})

const has_name = computed(() => {
  return [NAME_DESIGN, FULL_DESIGN].includes(meta.value.design)
})

const has_dropdown_icon = computed(() => {
  return meta.value.dropdown_icon?.enable
})

const username = computed(() => {
  if (auth_store.user.access_token) {
    return auth_store.user.name || auth_store.user.email
  }
  return 'William Tunner'
})

const trigger_menu_before_show = () => {
  if (canvas_store.page.group === 'B2B') {
    get_balance()
  }
}

const loading_balance = ref(false)
const balance = ref(null)

const get_balance = async () => {
  loading_balance.value = true

  const response = await services.AppService.get_balance()
  if (response.status === 200) {
    balance.value = response.data
  }
  loading_balance.value = false
}

const redirect = async () => {
  if ([PUBLISH_MODE, EMBED_MODE].includes(context_store.mode)) {
    window.location.href = `https://${context_store.app.booking_app_base_domain}/b2b-auth/callback?access_token=${auth_store.user.access_token}`
  }
}

const logout = () => {
  auth_store.$patch((state) => {
    Object.keys(state.user).forEach((k) => {
      state.user[k] = null
    })
  })

  if (context_store.mode === EMBED_MODE) return

  const after_logout_callback = meta.value.after_logout?.callback
  if (!after_logout_callback) return

  if (context_store.mode === PUBLISH_MODE) {
    if (after_logout_callback.handler === NO_ACTION_EVENT_HANDLER.id) {
      if (canvas_store.page.auth_required) {
        const indexed_page = canvas_store.pages.find((p) => p.indexed)
        router.push({
          name: 'publisher',
          params: { endpoint: indexed_page.endpoint }
        })
      }
    } else if (
      after_logout_callback.handler === PAGE_REDIRECT_EVENT_HANDLER.id
    ) {
      const endpoint = after_logout_callback.page_endpoint
      if (context_store.mode === PUBLISH_MODE) {
        router.push({ path: endpoint })
      } else {
        const page = canvas_store.pages.find((p) => p.endpoint === endpoint)
        if (!page) return
        change_page(page)
      }
    } else if (
      after_logout_callback.handler === LINK_REDIRECT_EVENT_HANDLER.id
    ) {
      window.location.href = after_logout_callback.link
    }
    localStorage.clear()

    // Cookies.remove('access_token', { path: '/', domain: get_base_domain() })
  }
}

const styles = computed(() => {
  let result = generate_common_node_styles(props.node)
  return result
})

const bind = computed(() => {
  let result = {
    style: styles.value
  }

  return result
})
</script>
