<template>
  <form-builder
    :style="{ '--q-primary': color }"
    product_name="bundle"
    v-if="payload"
    @submit="submit"
    :key="design === EXPEDIA_PACKAGE_FORM_DESIGN ? 'expedia' : 'default'"
    :node_id="node.id"
    :fields="
      design === EXPEDIA_PACKAGE_FORM_DESIGN ? meta_expedia.fields : meta.fields
    "
  />
</template>
<script setup>
import { computed, defineEmits, onMounted } from 'vue'
import { EXPEDIA_PACKAGE_FORM_DESIGN } from 'src/constants'
import { useI18n } from 'vue-i18n'
import keyBy from 'lodash.keyby'
import cloneDeep from 'lodash.clonedeep'
import { storeToRefs } from 'pinia'
import { stores } from 'src/stores'

import FormBuilder from 'src/components/common/form-builder'
import PlaceSelect from 'src/components/common/place-select'
import DatePicker from 'src/components/common/date-picker'
import TravelerSelect from 'src/components/common/traveler-select'
import CabinSelect from 'src/components/common/cabin-select'
import SeparateCheckInout from './separate-check-inout'
import AirportPicker from 'src/components/common/airport-picker'
import StarSelect from 'src/components/common/star-select'
import BoxField from 'src/components/common/box-field'
import SearchBtn from '../search-btn'

const props = defineProps({
  bundle: { type: Object, required: true },
  product: { type: Object, required: true },
  styles: { type: Object },
  color: { type: String },
  flight_campaign: { type: String },
  partner_id: { type: String },
  default_destination: { type: Object },
  design: { type: String },
  node: { type: Object },
  place_types: { type: Array, default: () => [] }
})
const { t } = useI18n()
const context_store = stores.use_context()
const package_store = stores.use_package()
const payload = computed(
  () => package_store.payload[props.node?.id]?.['bundle']
)
const { date_format } = storeToRefs(context_store)

const traveler_limits = keyBy(props.bundle.traveler_limits, 'traveler_type')
const emit = defineEmits(['submit'])

onMounted(() => {
  if (props.default_destination && payload.value) {
    payload.value.ar_airport = props.default_destination
  }
})

const rules = {
  dates: [(val) => !!val || ''],
  place: [(val) => !!val || ''],
  cabin_class: [(val) => !!val || ''],
  travelers: []
}

const handle_swap_airport = () => {
  const origin = cloneDeep(payload.value.dp_airport)
  const destination = cloneDeep(payload.value.ar_airport)
  payload.value.ar_airport = origin
  payload.value.dp_airport = destination
}

const buildExpectCodeAndType = (data) => {
  const { type, code, id } = data || {}
  let resultCode, resultType
  if (type === 'airport') {
    resultCode = code
    resultType = 'airport_code'
  } else if (['city', 'multi_city_vicinity', 'province_state'].includes(type)) {
    resultCode = code
    resultType = 'city_code'
  } else {
    resultCode = id
    resultType = 'place_id'
  }
  return [resultCode, resultType]
}

const meta_expedia = computed(() => ({
  fields: [
    {
      id: 'bundle.cabin_class',
      component: CabinSelect,
      hidden: false,
      model: 'cabin_class',
      order: 1,
      events: {},
      bind: {
        rounded: true,
        dense: true,
        rules: rules.cabin_class,
        outlined: true,
        hide_label_mode: true,
        'hide-bottom-space': true,
        cabin_classes: props.product.cabin_classes,
        'emit-value': true,
        'map-options': true,
        prepend_icon: 'class',
        label: t('common.cabin-class')
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 2,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'bundle.stars',
      component: StarSelect,
      hidden: false,
      model: 'stars',
      order: 2,
      events: {},
      bind: {
        class:'expedia-stars-select',
        dense: true,
        outlined: true,
        'hide-bottom-space': true,
        'emit-value': true,
        'map-options': true,
        label: t('common.hotel-stars'),
        hide_label_mode: true,
        rounded: true
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 2,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'bundle.box1',
      component: BoxField,
      hidden: false,
      model: 'box1',
      order: 3,
      events: {},
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 8,
          tablet: 0,
          mobile: 0
        }
      }
    },
    {
      id: 'bundle.airport',
      component: AirportPicker,
      hidden: false,
      model: 'payload',
      order: 4,
      bind: {
        product: props.product,
        color: props.color,
        has_code: true
      },
      events: {
        handle_swap_airport
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 5,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'bundle.dates',
      component: DatePicker,
      hidden: false,
      model: 'dates',
      order: 5,
      bind: {
        range: true,
        dense: true,
        outlined: true,
        'hide-bottom-space': true,
        rules: rules.dates,
        date_format: date_format.value,
        booking_cutoff_days: props.bundle.booking_cutoff_days,
        label: t('common.dates'),
        display_format: 'MMM DD'
      },
      events: {
        handle_swap_airport
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'bundle.travelers',
      component: TravelerSelect,
      hidden: false,
      model: 'travelers',
      order: 6,
      bind: {
        dense: true,
        rules: rules.travelers,
        outlined: true,
        'hide-bottom-space': true,
        with_rooms: true,
        max_rooms: 3,
        max_adults: +traveler_limits?.['adult']?.limit,
        max_infants: +traveler_limits?.['infant']?.limit,
        max_children: +traveler_limits?.['child']?.limit,
        traveler_limit_type: props.bundle?.traveler_limit_type,
        total_traveler_limit: props.bundle?.total_traveler_limit,
        traveler_types: props.product.traveler_types,
        default_traveler_count: props.bundle.default_traveler_count,
        label: t('common.travelers')
      },
      events: {
        handle_swap_airport
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'bundle.search-btn',
      component: SearchBtn,
      hidden: false,
      model: 'payload',
      order: 7,
      events: {},
      bind: {
        label: t('common.search'),
        color: props.color,
        rounded: true
      },
      dimension: {
        columns: {
          desktop: 1,
          tablet: 6,
          mobile: 12
        }
      }
    }
  ]
}))

const meta = computed(() => ({
  fields: [
    {
      id: 'bundle.origin',
      component: PlaceSelect,
      hidden: false,
      model: 'dp_airport',
      order: 1,
      events: {},
      bind: {
        cities_with_airports: true,
        dense: true,
        types: props.place_types,
        rules: rules.place,
        outlined: true,
        'hide-bottom-space': true,
        'fill-input': true,
        'hide-selected': true,
        static_route_enabled: props.product.static_route_enabled,
        destination_type: props.product.static_route_enabled ? 'departure' : '',
        prepend_icon: 'flight_takeoff',
        arrival_code:
          props.product.static_route_enabled && payload.value.ar_airport
            ? payload.value.ar_airport.code
            : '',
        label: t('common.origin'),
        has_code: true
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'bundle.destination',
      component: PlaceSelect,
      hidden: false,
      model: 'ar_airport',
      order: 2,
      events: {},
      bind: {
        cities_with_airports: true,
        dense: true,
        types: props.place_types,
        rules: rules.place,
        outlined: true,
        'hide-bottom-space': true,
        'fill-input': true,
        'hide-selected': true,
        static_route_enabled: props.product.static_route_enabled,
        destination_type: props.product.static_route_enabled ? 'arrival' : '',
        prepend_icon: 'place',
        arrival_code:
          props.product.static_route_enabled && payload.value.dp_airport
            ? payload.value.dp_airport.code
            : '',
        label: t('common.destination'),
        has_code: true
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'bundle.dates',
      component: DatePicker,
      hidden: false,
      model: 'flight_dates',
      order: 3,
      events: {},
      bind: {
        range: true,
        dense: true,
        outlined: true,
        'hide-bottom-space': true,
        date_format: date_format.value,
        booking_cutoff_days: props.bundle.booking_cutoff_days,
        label: t('common.departure-return')
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'bundle.search-btn',
      component: SearchBtn,
      hidden: false,
      model: 'payload',
      order: 4,
      events: {},
      bind: {
        label: t('common.search-now'),
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'bundle.travelers',
      component: TravelerSelect,
      hidden: false,
      model: 'travelers',
      order: 5,
      events: {},
      bind: {
        dense: true,
        rules: rules.travelers,
        outlined: true,
        'hide-bottom-space': true,
        with_rooms: true,
        max_rooms: 3,
        max_adults: +traveler_limits?.['adult']?.limit,
        max_infants: +traveler_limits?.['infant']?.limit,
        max_children: +traveler_limits?.['child']?.limit,
        traveler_limit_type: props.bundle?.traveler_limit_type,
        total_traveler_limit: props.bundle?.total_traveler_limit,
        traveler_types: props.product.traveler_types,
        default_traveler_count: props.bundle.default_traveler_count,
        label: t('common.travelers')
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'bundle.cabin_class',
      component: CabinSelect,
      hidden: false,
      model: 'cabin_class',
      order: 6,
      events: {},
      bind: {
        dense: true,
        rules: rules.cabin_class,
        outlined: true,
        'hide-bottom-space': true,
        cabin_classes: props.product.cabin_classes,
        'emit-value': true,
        'map-options': true,
        prepend_icon: 'class',
        label: t('common.cabin-class')
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'bundle.separate',
      component: SeparateCheckInout,
      hidden: false,
      model: 'form_data',
      model_path: 'separate',
      order: 7,
      events: {},
      bind: {
        dense: true,
        flight_dates: payload.value.flight_dates,
        date_format: date_format.value,
        hotel_dates: payload.value.hotel_dates,
        display_format: 'DD/MM/YYYY',
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    }
  ]
}))

const submit = () => {
  const {
    travelers,
    cabin_class,
    flight_dates,
    dp_airport,
    ar_airport,
    hotel_dates,
    stars
  } = payload.value || {}

  let process = 'flight'

  if (props.bundle.bundled) {
    process = 'bundle'
  }
  let [start_place_code, start_place_type] = buildExpectCodeAndType(dp_airport),
    [des_code, des_type] = buildExpectCodeAndType(ar_airport),
    [ht_des_code, ht_des_type] = buildExpectCodeAndType(ar_airport)

  const data = {
    process,
    currency_code: context_store.currency?.code,
    language_code: context_store.language?.code,
    package_id: props.bundle.id,
    travelers: JSON.stringify(travelers),
    expectation: JSON.stringify({
      fl_cabin_class: cabin_class,
      fl_departure_date: flight_dates[0],
      fl_return_date: flight_dates[1],
      fl_round_trip: true,
      start_place_code,
      start_place_type,
      des_code: ar_airport.code,
      // des_code,
      des_type,
      ht_des_code,
      ht_des_type,
      ht_checkin_date: hotel_dates?.[0] || flight_dates[0],
      ht_checkout_date: hotel_dates?.[1] || flight_dates[1],
      stars: stars
    })
  }
  if (props.flight_campaign) {
    data.flight_campaign = props.flight_campaign
  }
  if (props.partner_id) {
    data.partner_id = props.flight_campaign
  }
  emit('submit', data)
}
</script>
