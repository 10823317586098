import dayjs from 'dayjs'
import { Dialog } from 'quasar'

import {
  EMAIL_REGEX,
  LETTER_REGEX,
  PHONE_REGEX,
  URL_REGEX
} from 'src/constants/regexes'
import cloneDeep from 'lodash.clonedeep'
import { watch } from 'vue'

export const convert_string_to_date = (string_date, format = 'iso') => {
  let date
  if (format === 'iso') {
    date = dayjs(string_date)
  } else {
    date = dayjs(string_date, format)
  }
  return date
}

export const convert_date_to_string = (date, format) => {
  if (format === 'iso') return date.toISOString()
  return date.format(format)
}

export const get_now = () => {
  return dayjs()
}

export const change_datetime_format = (string_date, from_format, to_format) => {
  if (!string_date) return null

  let date = convert_string_to_date(string_date, from_format)

  if (to_format === 'iso') {
    return date.format()
  }
  return date.format(to_format)
}

export const object_to_array = (obj, key_field, value_field) => {
  let result = []
  Object.keys(obj).forEach((k) => {
    result.push({
      [key_field]: k,
      [value_field]: obj[k]
    })
  })
  return result
}

export const array_to_object = (array, key_field, value_field) => {
  let result = {}
  array.forEach((i) => {
    let key = i[key_field]
    if (key) {
      result[key] = i[value_field]
    }
  })
  return result
}

export const regex_validators = {
  email: (val) => {
    return EMAIL_REGEX.test(val)
  },
  phone: (val) => {
    return PHONE_REGEX.test(val)
  },
  letter: (val) => {
    if (val.charAt(0).trim()) {
      return LETTER_REGEX.test(val)
    }
    return false
  },
  url: (val) => {
    if (typeof val !== 'string') return false
    return URL_REGEX.test(val)
  }
}

export const measure_file_size_unit = (bytes) => {
  const gap = 1024
  const decimal_digits = 2

  const kb_size = (bytes / gap).toFixed(decimal_digits)
  if (kb_size < gap) return `${kb_size} KB`

  const mb_size = (kb_size / gap).toFixed(decimal_digits)
  if (mb_size < gap) return `${mb_size} MB`

  const gb_size = (mb_size / gap).toFixed(decimal_digits)
  if (gb_size < gap) return `${gb_size} GB`

  return `${(gb_size / gap).toFixed(decimal_digits)} TB`
}

export const convert_file_to_base64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

export const nest_nodes = (flattened_nodes) => {
  const nodes = JSON.parse(JSON.stringify(flattened_nodes))
  const node_ids = Object.keys(nodes)

  Object.values(flattened_nodes).forEach((n) => {
    const node = nodes[n.id]
    if (node.parent_id && nodes[node.parent_id]) {
      const parent = nodes[node.parent_id]
      const children = parent.children || []
      children.push(node)
      parent.children = children
      node_ids.splice(node_ids.indexOf(node.id), 1)
    }
  })

  const result = Object.values(nodes).filter((n) => node_ids.includes(n.id))

  sort_nodes(result)

  return result
}

const sort_nodes = (ns) => {
  ns.sort((a, b) => a.order - b.order)
  ns.forEach((n) => {
    if (n.children) sort_nodes(n.children)
  })
}

export const flatten_nodes = (nested_nodes) => {
  let result = {}

  const handle = (ns) => {
    ns.forEach((n) => {
      result[n.id] = n
      if (n.children) handle(n.children)
      n.children = []
    })
  }
  handle(cloneDeep(nested_nodes))

  return result
}
export const get_node_descendants = (node_id, nodes) => {
  let result = []

  const handle = (parent_id) => {
    const children = nodes.filter((n) => n.parent_id === parent_id)
    children.forEach((cn) => {
      result.push(cn)
      handle(cn.id)
    })
  }

  handle(node_id)
  return result
}
export const get_node_children = (node_id, nodes) => {
  const result = nodes.filter((n) => n.parent_id === node_id)

  result.sort((a, b) => a.order - b.order)

  return result
}

export const gen_uuid4 = () => {
  return crypto.randomUUID().replaceAll('-', '')
}

export const get_query_params = () => {
  return Object.fromEntries(new URLSearchParams(window.location.search))
}

export const form_rules = {
  number: (v) => !isNaN(v) || 'Invalid number',
  require: (v) => (v !== null && v !== undefined && v !== '') || 'Required'
}

export const load_language_translations = async ({
  context_store,
  translation_store,
  services,
  i18n
}) => {
  const app_id = context_store.app.id
  const language = context_store.language
  const lang_translations = translation_store.data[app_id]?.[language.code]
  const current_messages = i18n.getLocaleMessage(language.code) ?? {}

  if (lang_translations) {
    i18n.setLocaleMessage(language.code, {
      ...current_messages,
      ...lang_translations
    })
  }
  const response = await services.AppService.fetch_language_translations({
    language_code: language.code
  })

  if (response.status === 200) {
    i18n.setLocaleMessage(language.code, {
      ...current_messages,
      ...response?.data
    })
    translation_store.$patch((state) => {
      if (!state.data[app_id]) state.data[app_id] = {}
      state.data[app_id][language.code] = response.data
    })
  }
}

//helper
export const add_days_to_date = (date_str, num_days) => {
  const date_obj = new Date(date_str)
  date_obj.setDate(date_obj.getDate() + num_days)
  return date_obj.toISOString().split('T')[0]
}

export const is_date_in_current_month = (date_str) => {
  const date_obj = new Date(date_str)

  const given_month = date_obj.getMonth()
  const given_year = date_obj.getFullYear()

  const current_date = new Date()
  const current_month = current_date.getMonth()
  const current_year = current_date.getFullYear()

  return given_month === current_month && given_year === current_year
}

export const get_next_month_string = () => {
  const current_date = new Date()

  const current_month = current_date.getMonth()
  const current_year = current_date.getFullYear()

  const next_month = (current_month + 1) % 12
  const next_year = current_month === 11 ? current_year + 1 : current_year

  return `${next_year}/${String(next_month + 1).padStart(2, '0')}`
}

export const get_current_month_string = () => {
  const current_date = new Date()

  const current_month = current_date.getMonth() + 1 // Tháng đếm từ 0
  const current_year = current_date.getFullYear()

  return `${current_year}/${String(current_month).padStart(2, '0')}`
}

export const get_default_or_first_item = (array) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i].is_default) {
      return array[i]
    }
  }
  return array[0]
}

export function total_display(currency, total) {
  if (currency) {
    const total_value = total || 0
    const rounding =
      currency.rounding || currency.rounding === 0 ? currency.rounding : 2
    const string_totals = parseFloat(total_value).toFixed(rounding).split('.')
    const first_total = string_totals[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    if (string_totals.length > 1) {
      const last_total = string_totals[1]
      return `${first_total}.${last_total}`
    }
    return first_total
  }
  return total || ''
}

export function handle_page_scroll(cb) {
  const page = document.getElementById('root-page')
  if (!page) return
  page.addEventListener('scroll', () => {
    cb?.()
  })
}

export const show_confirm_dialog = (params) => {
  const {
    message = 'Are you sure to proceed?',
    cancel = true,
    persistent = true,
    position = 'top',
    onOk = () => {},
    onCancel = () => {},
    onDismiss = () => {}
  } = params || {}
  Dialog.create({
    message,
    cancel,
    persistent,
    position,
    ok: {
      color: 'primary'
    }
  })
    .onOk(onOk)
    .onCancel(onCancel)
    .onDismiss(onDismiss)
}

export const get_base_domain = () => {
  if (!window) return
  if (window.location.hostname === 'localhost') {
    return 'localhost'
  } else {
    let counter = 1
    const base_domain_parts = window.location.hostname.split('.')
    if (base_domain_parts > 3) counter = 2
    return `.${base_domain_parts.slice(counter).join('.')}`
  }
}

function debounce(fn, delay) {
  let timeout
  return function (...args) {
    clearTimeout(timeout)
    timeout = setTimeout(() => fn.apply(this, args), delay)
  }
}

export const watch_debounced = (source, callback, delay = 300) => {
  const debouncedFn = debounce(callback, delay)
  watch(source, (newValue, oldValue) => {
    debouncedFn(newValue, oldValue)
  })
}
