<template>
  <form-builder
    :style="{ '--q-primary': color }"
    v-if="payload"
    @submit="submit"
    product_name="tour_package"
    :node_id="node.id"
    :fields="meta.fields"
  />
</template>
<script setup>
import { computed, defineEmits, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import keyBy from 'lodash.keyby'

import { stores } from 'src/stores'

import FormBuilder from 'src/components/common/form-builder'
import PlaceSelect from 'src/components/common/place-select/index.vue'
import DatePicker from 'src/components/common/date-picker/index.vue'
import TravelerSelect from 'src/components/common/traveler-select/index.vue'
import SearchBtn from '../search-btn/index.vue'

const props = defineProps({
  product: { type: Object, required: true },
  tour_package: { type: Object, required: true },
  styles: { type: Object },
  color: { type: String },
  flight_campaign: { type: String },
  partner_id: { type: String },
  default_destination: { type: Object },
  node: { type: Object },
  place_types: { type: Array, default: () => [] }
})

const { t } = useI18n()
const context_store = stores.use_context()
const package_store = stores.use_package()
const payload = computed(
  () => package_store.payload[props.node?.id]?.['tour_package']
)

const emit = defineEmits(['submit'])

onMounted(() => {
  if (props.default_destination && payload.value) {
    payload.value.place = props.default_destination
  }
})

const traveler_limits = keyBy(
  props.tour_package.traveler_limits,
  'traveler_type'
)


const rules = {
  dates: [(val) => !!val || 'Required'],
  place: [(val) => !!val || 'Required'],
  travelers: []
}

const meta = computed(() => ({
  fields: [
    {
      id: 'tour_package.place',
      component: PlaceSelect,
      hidden: false,
      model: 'place',
      order: 1,
      events: {},
      bind: {
        dense: true,
        types: props.place_types,
        rules: rules.place,
        outlined: true,
        'hide-bottom-space': true,
        'hide-selected': true,
        'fill-input': true,
        prepend_icon: 'place',
        has_code: true,
        label: t('common.destination')
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'tour_package.dates',
      component: DatePicker,
      hidden: false,
      model: 'dates',
      order: 2,
      events: {},
      bind: {
        dense: true,
        outlined: true,
        booking_cutoff_days: props.tour_package.booking_cutoff_days,
        rules: rules.dates,
        'hide-bottom-space': true,
        label: t('common.departure')
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'tour_package.travelers',
      component: TravelerSelect,
      hidden: false,
      model: 'travelers',
      order: 3,
      events: {},
      bind: {
        dense: true,
        max_rooms: 3,
        rules: rules.travelers,
        max_adults: +traveler_limits?.['adult']?.limit,
        max_infants: +traveler_limits?.['infant']?.limit,
        max_children: +traveler_limits?.['child']?.limit,
        traveler_limit_type: props.tour_package?.traveler_limit_type,
        total_traveler_limit: props.tour_package?.total_traveler_limit,
        default_traveler_count: props.tour_package?.default_traveler_count,
        traveler_types: props.product.traveler_types,
        outlined: true,
        'hide-bottom-space': true,
        label: t('common.travelers')
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'flight.search-btn',
      component: SearchBtn,
      hidden: false,
      disabled: false,
      model: 'payload',
      order: 4,
      events: {},
      bind: {
        label: t('common.search-now'),
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    }
  ]
}))

const submit = () => {
  const { travelers, place, dates } = payload.value || {}

  emit('submit', {
    process: 'tour_package',
    currency_code: context_store.currency?.code,
    language_code: context_store.language?.code,
    package_id: props.tour_package.id,
    travelers: JSON.stringify(travelers),
    expectation: JSON.stringify({
      is_separate: false,
      tp_des_code: place.type === 'airport' ? place.code : place.id,
      tp_des_type: place.type === 'airport' ? 'airport_code' : 'place_id',
      tp_start_date: dates
    }),
    flight_campaign: props.flight_campaign,
    partner_id: props.partner_id
  })
}
</script>
