<template>
  <q-img
    :style="styles"
    :src="modelValue.file?.url"
    @click="handle_click"
    height="100%"
    width="100%"
  >
    <template
      v-if="
        [
          PACKAGE_FORM_POPUP_EVENT_HANDLER.id,
          TRIPS_FORM_POPUP_EVENT_HANDLER.id
        ].includes(modelValue?.events?.click?.handler)
      "
    >
      <q-dialog v-model="dialog_showed" persistent>
        <q-card style="max-width: 99vw; width: 1000px">
          <q-item>
            <q-item-section>
              <q-item-label
                v-if="modelValue.show_title || modelValue.show_caption"
                class="text-h6"
              >
                <span v-if="modelValue.show_title">
                  {{ modelValue.title[context_store.language.code] }}
                </span>
                <span v-if="modelValue.show_title && modelValue.show_caption">
                  -
                </span>
                <span v-if="modelValue.show_caption">
                  {{ modelValue.caption[context_store.language.code] }}
                </span>
              </q-item-label>
              <q-item-label
                v-else-if="modelValue.events.click.destination"
                class="text-h6"
              >
                <span
                  v-if="
                    modelValue.events?.click?.handler ===
                    TRIPS_FORM_POPUP_EVENT_HANDLER.id
                  "
                >
                  Trips -
                </span>
                <span
                  class="text-capitalize"
                  v-else-if="modelValue.events.click.product_combine"
                >
                  {{ meta.events.click.product_combine }} -
                </span>
                <span>{{ modelValue.events.click.destination.name }}</span>
              </q-item-label>
              <q-item-label v-else class="text-h6">Search</q-item-label>
            </q-item-section>
            <q-item-section avatar>
              <q-btn flat round dense icon="close" v-close-popup />
            </q-item-section>
          </q-item>
          <q-separator />
          <q-item
            v-if="
              modelValue?.events?.click?.handler ===
              PACKAGE_FORM_POPUP_EVENT_HANDLER.id
            "
            class="full-width"
          >
            <package-form
              v-if="dialog_showed"
              :default_destination="modelValue.events.click.destination"
              class="full-width"
              :styles="styles"
              :packages="packages"
              :node="node"
              :color="modelValue.events.click.package_form_color"
              :products="products"
              :handle_submit="handle_submit"
              :product_combine="modelValue.events.click.product_combine"
            />
          </q-item>
          <q-item
            v-if="
              modelValue?.events?.click?.handler ===
              TRIPS_FORM_POPUP_EVENT_HANDLER.id
            "
            class="full-width"
          >
            <trips-form
              v-if="dialog_showed"
              :key="modelValue.events.click.destination.id"
              :default_destination="modelValue.events.click.destination"
              class="full-width"
              :node="node"
              :color="modelValue.events.click.package_form_color"
              @submit="handle_submit"
            />
          </q-item>
        </q-card>
      </q-dialog>
    </template>
    <div class="absolute-full flex q-column" v-if="has_content">
      <q-space />
      <div v-if="modelValue.title" class="text-subtitle1">
        {{ modelValue.title[context_store.language.code] }}
      </div>
      <div v-if="modelValue.show_caption">
        <q-item-label class="text-caption">
          {{ modelValue.caption[context_store.language.code] }}
        </q-item-label>
      </div>
    </div>
  </q-img>
</template>
<script setup>
import { computed, ref } from 'vue'
import PackageForm from 'src/components/common/package-form'
import TripsForm from 'src/components/common/trip-form'

import keyBy from 'lodash.keyby'
import { stores } from 'src/stores'
import {
  EMBED_MODE,
  LINK_REDIRECT_EVENT_HANDLER,
  PACKAGE_FORM_POPUP_EVENT_HANDLER,
  PAGE_REDIRECT_EVENT_HANDLER,
  TRIPS_FORM_POPUP_EVENT_HANDLER,
  PUBLISH_MODE
} from 'src/constants'
import { change_page } from 'src/composables/editor'
import { useRouter } from 'vue-router'

const props = defineProps({
  modelValue: { type: Object },
  node: { type: Object, required: true }
})

const router = useRouter()

const context_store = stores.use_context()
const canvas_store = stores.use_canvas()
const auth_store = stores.use_auth()

const type = computed(() => props.node.meta.cell_type)

const styles = computed(() => {
  const node_styles = props.node.styles || {}

  let result = {}

  const corners = node_styles.corners
  if (corners?.values) {
    result['border-radius'] = corners.values.map((v) => `${v}px`).join(' ')
  }

  if (props.modelValue?.events?.click) {
    result['cursor'] = 'pointer'
  }

  return result
})

const dialog_showed = ref(false)

const has_content = computed(() => {
  if (props.modelValue?.show_title) return true
  if (props.modelValue?.show_caption) return true
  return false
})

const handle_click = () => {
  const click_event = props.modelValue.events?.click
  if (!click_event) return
  if (
    [
      PACKAGE_FORM_POPUP_EVENT_HANDLER.id,
      TRIPS_FORM_POPUP_EVENT_HANDLER.id
    ].includes(click_event.handler)
  ) {
    dialog_showed.value = true
  } else if (click_event.handler === PAGE_REDIRECT_EVENT_HANDLER.id) {
    const endpoint = click_event.page_endpoint
    if (context_store.mode === PUBLISH_MODE) {
      router.push({ path: endpoint })
    } else if (context_store.mode === EMBED_MODE) {
      window.location.href = endpoint
    } else {
      const page = canvas_store.pages.find((p) => p.endpoint === endpoint)
      if (!page) return
      change_page(page)
    }
  } else if (click_event.handler === LINK_REDIRECT_EVENT_HANDLER.id) {
    window.open(click_event.link, '_blank')
  }
}

const products = keyBy(context_store.products, 'code')

const packages = keyBy(
  context_store.packages.map((x) => ({
    ...x,
    product_codes: x?.product_codes?.join('--')
  })),
  'product_codes'
)

const handle_submit = (data) => {
  if (data) {
    if (auth_store.user.access_token) {
      data.access_token = auth_store.user.access_token
    }
    const booking_app_base_domain = context_store.app.booking_app_base_domain
    const qs = new URLSearchParams(data).toString()
    location.href = `https://${booking_app_base_domain}/shopping/processes/${data.process}?${qs}`
  }
}
</script>
