<template>
  <form-builder
    v-if="payload"
    :key="update_key"
    :fields="meta.fields"
    :node_id="node.id"
    :style="{ '--q-primary': color }"
    product_name="train"
    @submit="submit"
  />
</template>
<script setup>
import { computed, defineEmits, onMounted, ref } from 'vue'
import clone_deep from 'lodash.clonedeep'
import key_by from 'lodash.keyby'

import { stores } from 'src/stores'

import FormBuilder from 'src/components/common/form-builder'
import { get_meta_default } from './meta_default'

const props = defineProps({
  flight: { type: Object, required: true },
  product: { type: Object, required: true },
  styles: { type: Object },
  color: { type: String, default: '#1976d2' },
  flight_campaign: { type: String },
  partner_id: { type: String },
  default_destination: { type: Object },
  design: { type: String },
  node: { type: Object },
  setting: { type: Object }
})

const context_store = stores.use_context()
const package_store = stores.use_package()
const payload = computed(() => package_store.payload[props.node?.id]?.['train'])

const update_key = ref(0)

onMounted(() => {
  if (payload.value && props.default_destination) {
    payload.value.ar_airport = props.default_destination
  }
  const default_values = props.setting?.default_values ?? {}

  if (default_values?.dates) {
    payload.value.dates = default_values.dates
  }

  if (
    default_values?.ar_airport !== undefined ||
    default_values?.ar_airport === null
  ) {
    payload.value.ar_airport = default_values.ar_airport
  }

  if (
    default_values?.dp_airport !== undefined ||
    default_values?.dp_airport === null
  ) {
    payload.value.dp_airport = default_values.dp_airport
  }

  if (default_values?.travelers) {
    payload.value.travelers = default_values.travelers
  }

  if (default_values?.group_booking !== undefined) {
    payload.value.group_booking = Boolean(default_values.group_booking)
  }

  if (default_values?.tn_round_trip !== undefined) {
    payload.value.tn_round_trip = Boolean(default_values.tn_round_trip)
  }

  update_key.value += 1
})

const traveler_limits = key_by(props.flight.traveler_limits, 'traveler_type')
const place_types = ['railway_station']

const emit = defineEmits(['submit'])

const rules = {
  dp_airport: [(v) => !!v || ''],
  ar_airport: [(v) => !!v || ''],
  dates: [(val) => !!val || ''],
  place: [(val) => !!val || ''],
  cabin_class: [(val) => !!val || ''],
  travelers: []
}

const handle_swap_airport = () => {
  const origin = clone_deep(payload.value.dp_airport)
  const destination = clone_deep(payload.value.ar_airport)
  payload.value.ar_airport = origin
  payload.value.dp_airport = destination
}

const handle_validate_place = (type, place) => {
  const dp_airport_id = payload.value.dp_airport?.id ?? ""
  const ar_airport_id = payload.value.ar_airport?.id ?? ""
  if (type === 'origin' && ar_airport_id === place.id) {
    return false
  }
  if (type === 'destination' && dp_airport_id === place.id) {
    return false
  }
  return true
}

const meta = get_meta_default({
  props,
  rules,
  payload,
  place_types,
  traveler_limits,
  handle_swap_airport,
  handle_validate_place,
})

const submit = () => {
  const {
    travelers,
    ar_airport,
    dp_airport,
    dates,
    group_booking,
    tn_round_trip
  } = payload.value || {}

  const data = {
    process: 'train',
    product_code: 'train',
    type: 'railway_station',
    currency_code: context_store.currency?.code,
    language_code: context_store.language?.code,
    package_id: props.flight.id,
    travelers: JSON.stringify(travelers),
    group_booking: Boolean(group_booking),
    expectation: JSON.stringify({
      tn_departure_code: dp_airport.code,
      tn_departure_type: dp_airport.type,
      tn_arrival_code: ar_airport.code,
      tn_arrival_type: ar_airport.type,
      tn_return_date: tn_round_trip ? dates[1] : null,
      tn_departure_date: tn_round_trip ? dates[0] : dates,
      tn_round_trip: Boolean(tn_round_trip),
      group_booking: Boolean(group_booking)
    })
  }
  emit('submit', data)
}
</script>
