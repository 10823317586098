<template>
  <form-builder
    :style="{ '--q-primary': color }"
    :key="design === EXPEDIA_PACKAGE_FORM_DESIGN ? 'expedia' : 'default'"
    v-if="payload"
    @submit="submit"
    product_name="hotel"
    :node_id="node.id"
    :fields="
      design === EXPEDIA_PACKAGE_FORM_DESIGN ? meta_expedia.fields : meta.fields
    "
  />
</template>
<script setup>
import { computed, defineEmits, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import keyBy from 'lodash.keyby'
import isNumber from 'lodash/isNumber'

import { stores } from 'src/stores'
import { EXPEDIA_PACKAGE_FORM_DESIGN } from 'src/constants'

import FormBuilder from 'src/components/common/form-builder'
import PlaceSelect from 'src/components/common/place-select'
import DatePicker from 'src/components/common/date-picker'
import TravelerSelect from 'src/components/common/traveler-select'
import SearchBtn from '../search-btn'

const emit = defineEmits(['submit'])

const props = defineProps({
  product: { type: Object, required: true },
  hotel: { type: Object, required: true },
  styles: { type: Object },
  color: { type: String },
  flight_campaign: { type: String },
  partner_id: { type: String },
  default_destination: { type: Object },
  design: { type: String },
  node: { type: Object },
  place_types: { type: Array, default: () => [] }
})
const { t } = useI18n()
const context_store = stores.use_context()
const package_store = stores.use_package()
const payload = computed(() => package_store.payload[props.node?.id]?.['hotel'])

onMounted(() => {
  if (props.default_destination && payload.value) {
    payload.value.place = props.default_destination
  }
})

const traveler_limits = keyBy(props.hotel.traveler_limits, 'traveler_type')

const rules = {
  dates: [(val) => !!val || 'Required'],
  place: [(val) => !!val || 'Required'],
  travelers: []
}

const meta = computed(() => ({
  fields: [
    {
      id: 'hotel.place',
      component: PlaceSelect,
      hidden: false,
      model: 'place',
      order: 1,
      events: {},
      bind: {
        dense: true,
        types: props.place_types,
        rules: rules.place,
        outlined: true,
        'hide-bottom-space': true,
        'hide-selected': true,
        'fill-input': true,
        prepend_icon: 'place',
        has_code: true,
        disable_loadmore: true,
        with_properties: true,
        hotel_places: true,
        label: t('common.where-are-you-going')
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'hotel.dates',
      component: DatePicker,
      hidden: false,
      model: 'dates',
      order: 2,
      events: {},
      bind: {
        readonly: false,
        range: true,
        dense: true,
        outlined: true,
        booking_cutoff_days: props.hotel.booking_cutoff_days,
        rules: rules.dates,
        'hide-bottom-space': true,
        label: t('common.checkin-checkout')
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'hotel.travelers',
      component: TravelerSelect,
      hidden: false,
      model: 'travelers',
      order: 3,
      events: {},
      bind: {
        dense: true,
        max_rooms: 3,
        rules: rules.travelers,
        max_adults: +traveler_limits?.['adult']?.limit,
        max_infants: +traveler_limits?.['infant']?.limit,
        max_children: +traveler_limits?.['child']?.limit,
        traveler_limit_type: props.hotel?.traveler_limit_type,
        total_traveler_limit: props.hotel?.total_traveler_limit,
        default_traveler_count: props.hotel?.default_traveler_count,
        traveler_types: props.product.traveler_types,
        with_rooms: true,
        outlined: true,
        'hide-bottom-space': true,
        label: t('common.travelers')
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'flight.search-btn',
      component: SearchBtn,
      hidden: false,
      disabled: false,
      model: 'payload',
      order: 7,
      events: {},
      bind: {
        label: t('common.search-now'),
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    }
  ]
}))

const meta_expedia = computed(() => ({
  fields: [
    {
      id: 'hotel.place',
      component: PlaceSelect,
      hidden: false,
      disabled: false,
      model: 'place',
      order: 1,
      events: {},
      bind: {
        dense: true,
        types: props.place_types,
        rules: rules.place,
        outlined: true,
        'hide-bottom-space': true,
        'hide-selected': true,
        'fill-input': true,
        prepend_icon: 'place',
        has_code: true,
        disable_loadmore: true,
        with_properties: true,
        hotel_places: true,
        label: t('common.going-to')
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 4,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'hotel.dates',
      component: DatePicker,
      hidden: false,
      model: 'dates',
      order: 2,
      events: {},
      bind: {
        readonly: false,
        range: true,
        dense: true,
        outlined: true,
        booking_cutoff_days: props.hotel.booking_cutoff_days,
        rules: rules.dates,
        'hide-bottom-space': true,
        label: t('common.dates')
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'hotel.travelers',
      component: TravelerSelect,
      hidden: false,
      model: 'travelers',
      order: 3,
      events: {},
      bind: {
        dense: true,
        max_rooms: 3,
        rules: rules.travelers,
        max_adults: +traveler_limits?.['adult']?.limit,
        max_infants: +traveler_limits?.['infant']?.limit,
        max_children: +traveler_limits?.['child']?.limit,
        traveler_limit_type: props.hotel?.traveler_limit_type,
        total_traveler_limit: props.hotel?.total_traveler_limit,
        default_traveler_count: props.hotel?.default_traveler_count,
        traveler_types: props.product.traveler_types,
        with_rooms: true,
        outlined: true,
        'hide-bottom-space': true,
        label: t('common.travelers')
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'flight.search-btn',
      component: SearchBtn,
      hidden: false,
      disabled: false,
      model: 'payload',
      order: 4,
      events: {},
      bind: {
        label: t('common.search'),
        color: props.color,
        rounded: true
      },
      dimension: {
        columns: {
          desktop: 2,
          tablet: 6,
          mobile: 12
        }
      }
    }
  ]
}))

const submit = () => {
  const { travelers, place, dates } = payload.value || {}

  const data = {
    process: 'hotel',
    place_type: isNumber(place?.star) ? 'hotel' : place.type,
    place_id: place.id,
    currency_code: context_store.currency?.code,
    language_code: context_store.language?.code,
    package_id: props.hotel.id,
    travelers: JSON.stringify(travelers),
    expectation: JSON.stringify({
      ht_des_code: place.type === 'airport' ? place.code : place.id,
      ht_checkin_date: dates[0],
      ht_checkout_date: dates[1],
      ht_des_type: place.type === 'airport' ? 'airport_code' : 'place_id',
      is_separate: false
    }),
    flight_campaign: props.flight_campaign,
    partner_id: props.partner_id
  }
  emit('submit', data)
}
</script>
