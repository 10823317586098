import IamService from './iam'
import CmsService from './cms'
import AppService from './app'
import {stores} from 'src/stores'


export const use_services = () => {
  let result = {}
  const env_store = stores.use_env()

  let services = {
    IamService: [IamService, env_store.IAM_URL],
    CmsService: [CmsService, env_store.CMS_URL],
    AppService: [AppService, 'http://localhost:5000']
  }

  const context_store = stores.use_context()

  if (context_store.app) {
    services.AppService[1] = `https://${context_store.app.booking_app_default_domain}/api`
  }

  Object.keys(services).forEach(service_name => {
    const [ServiceClass, base_url] = services[service_name]
    result[service_name] = new ServiceClass(base_url)
  })

  return result
}
