<template>
  <form-builder
  :style="{ '--q-primary': color }"
    v-if="payload"
    @submit="submit"
    product_name="car"
    :node_id="node.id"
    :fields="meta.fields"
  />
</template>
<script setup>
import { computed, defineProps, defineEmits, onMounted } from 'vue'
import { QInput } from 'quasar'
import { useI18n } from 'vue-i18n'

import { stores } from 'src/stores'

import FormBuilder from 'src/components/common/form-builder'
import PlaceSelect from 'src/components/common/place-select/index.vue'
import DatePicker from 'src/components/common/date-picker/index.vue'
import TimePicker from 'src/components/common/time-picker/index.vue'
import DifferentDropoffLocation from './different-dropoff-location/index.vue'
import SearchBtn from '../search-btn/index.vue'

const emit = defineEmits(['submit'])
const props = defineProps({
  car_rental: { type: Object, required: true },
  styles: { type: Object },
  color: { type: String },
  flight_campaign: { type: String },
  partner_id: { type: String },
  default_destination: { type: Object },
  node: { type: Object },
  place_types: { type: Array, default: () => [] }
})

const { t } = useI18n()
const context_store = stores.use_context()
const package_store = stores.use_package()
const payload = computed(() => package_store.payload[props.node?.id]?.['car'])

onMounted(() => {
  if (props.default_destination && payload.value) {
    payload.value.pickup_location = props.default_destination
  }
})

const rules = {
  dates: [(val) => !!val || 'Required'],
  place: [(val) => !!val || 'Required'],
  travelers: []
}

const meta = computed(() => ({
  fields: [
    {
      id: 'car.pickup_location',
      component: PlaceSelect,
      hidden: false,
      model: 'pickup_location',
      order: 1,
      events:{},
      bind: {
        dense: true,
        types: props.place_types,
        rules: rules.place,
        outlined: true,
        'hide-bottom-space': true,
        'hide-selected': true,
        'fill-input': true,
        prepend_icon: 'place',
        label: t('common.pickup-location')
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'car.pickup_date',
      component: DatePicker,
      hidden: false,
      model: 'pickup_date',
      order: 2,
      events: {},
      bind: {
        dense: true,
        outlined: true,
        rules: rules.dates,
        booking_cutoff_days: props.car_rental.booking_cutoff_days,
        'hide-bottom-space': true,
        label: t('common.pickup-date')
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'car.pickup_time',
      component: TimePicker,
      hidden: false,
      model: 'pickup_time',
      order: 2,
      events: {},
      bind: {
        dense: true,
        outlined: true,
        rules: rules.dates,
        'hide-bottom-space': true,
        label: t('common.pickup-time')
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },

    {
      id: 'car.driver_age',
      component: QInput,
      hidden: false,
      model: 'driver_age',
      order: 3,
      events: {},
      bind: {
        dense: true,
        type: 'number',
        outlined: true,
        'hide-bottom-space': true,
        rules: rules.place,
        label: t('common.driver-age')
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'car.diff_dropoff',
      component: DifferentDropoffLocation,
      hidden: false,
      model: 'diff_dropoff',
      order: 4,
      events: {},
      bind: {
        place_types:props.place_types,
        form_data: payload.value.dropoff_data
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'car.dropoff_date',
      component: DatePicker,
      hidden: false,
      model: 'dropoff_date',
      order: 5,
      events: {},
      bind: {
        dense: true,
        outlined: true,
        rules: rules.dates,
        booking_cutoff_days: props.car_rental.booking_cutoff_days,
        'hide-bottom-space': true,
        label: t('common.dropoff-date')
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'car.dropoff_time',
      component: TimePicker,
      hidden: false,
      model: 'dropoff_time',
      order: 6,
      events: {},
      bind: {
        dense: true,
        outlined: true,
        rules: rules.dates,
        'hide-bottom-space': true,
        label: t('common.dropoff-time')
      },
      style: {
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    },
    {
      id: 'car.search-btn',
      component: SearchBtn,
      hidden: false,
      disabled: false,
      model: 'payload',
      order: 7,
      events: {},
      bind: {
        label: t('common.search-now'),
        color: props.color
      },
      dimension: {
        columns: {
          desktop: 3,
          tablet: 6,
          mobile: 12
        }
      }
    }
  ]
}))

const submit = () => {
  const {
    driver_age,
    diff_dropoff,
    pickup_location,
    pickup_date,
    pickup_time,
    dropoff_data,
    dropoff_date,
    dropoff_time
  } = payload.value || {}

  const data = {
    process: 'car_rental',
    currency_code: context_store.currency?.code,
    language_code: context_store.language?.code,
    package_id: props.car_rental.id,
    travelers: JSON.stringify([]),
    expectation: JSON.stringify({
      cr_driver_age: driver_age,
      cr_diff_dropoff_location: diff_dropoff,
      cr_pickup_place_code:
        pickup_location.type === 'airport' ? pickup_location.code : null,
      cr_pickup_date: pickup_date,
      cr_pickup_time: pickup_time,
      cr_pickup_place_type: 'airport_code',
      cr_dropoff_place_code: dropoff_data.dropoff_location?.code,
      cr_dropoff_date: dropoff_date,
      cr_dropoff_time: dropoff_time,
      cr_dropoff_place_type: 'airport_code',
      is_separate: false
    }),
    flight_campaign: props.flight_campaign,
    partner_id: props.partner_id
  }
  emit('submit', data)
}
</script>
