<template>
  <q-list
    :separator="destination_with_country ? false : separator"
    :class="!separator ? 'egs-place-select-list' : 'egs-mb-place-select-list'"
  >
    <template v-if="options.length">
      <template v-for="(option, index) in options" :key="option.id">
        <item
          class="q-py-md"
          v-intersect="(e) => handle_intersect(e, options.length - index < 5)"
          :destination_with_country="destination_with_country"
          :option="option"
          :mode-value="modelValue"
          :is_frist_item="index === 0"
          @select="handle_select"
        />
      </template>
    </template>
  </q-list>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
import Item from './item'

const props = defineProps({
  modelValue: { type: [Object, Array, String] },
  options: { type: Array, default: [] },
  separator: { type: Boolean, default: false },
  fetching: { type: Boolean, default: false },
  destination_with_country: { type: Boolean, default: false }
})

const emit = defineEmits(['select', 'loadmore'])
const handle_select = (option) => emit('select', option)

const handle_intersect = (isShow, can_loadmore) => {
  if (can_loadmore && isShow && !props.fetching) {
    emit('loadmore')
  }
}
</script>
