import { createI18n } from 'vue-i18n'
import { DEFAULT_LANGUAGE } from 'src/constants'

export const i18n = createI18n({
  locale: DEFAULT_LANGUAGE.code,
  globalInjection: true,
  messages: {
    'en-US': {
      'common.round-trip': 'round trip',
      'common.one-way': 'one way',
      'common.origin': 'origin',
      'common.destination': 'destination',
      'common.departure-return': 'Dates',
      'common.travelers': 'Traveller',
      'common.adults': 'adults',
      'common.children': 'children',
      'common.infants': 'infants',
      'common.cabin-class': 'cabin class',
      'common.search-now': 'Search Now',
      'common.search': 'Search',
      'common.airport': 'airport',
      'common.pickup-date': 'pickup date',
      'common.pickup-time': 'pickup time',
      'common.return-date': 'return date',
      'common.return-time': 'return time',
      'common.where-are-you-going': 'Destination',
      'common.checkin-checkout': 'Dates',
      'common.multi-city': 'Multi City',
      'common.part-of-my-stay': 'Only Need Accommodation For Part Of My Trip',
      'common.pickup-location': 'pickup location',
      'common.driver-age': 'driver age',
      'common.dropoff-date': 'dropoff date',
      'common.dropoff-time': 'dropoff time',
      'components.shopping.forms.common.cabin-select.options.first': 'First',
      'components.shopping.forms.common.cabin-select.options.economy':
        'Economy',
      'components.shopping.forms.common.cabin-select.options.premium':
        'Premium Economy',
      'components.shopping.forms.common.cabin-select.options.business':
        'Business',
      'common.any': 'Any',
      'common.1-star': '1 Star',
      'common.2-star': '2 Star',
      'common.3-star': '3 Star',
      'common.4-star': '4 Star',
      'common.5-star': '5 Star',
    }
  },
  legacy: false
})
