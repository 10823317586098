import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import DatePicker from 'src/components/common/date-picker'
import TravelerSelect from 'src/components/common/traveler-select'
import StarSelect from 'src/components/common/star-select/index.vue'
import AirportPicker from 'src/components/common/airport-picker/index.vue'
import SeparateCheckInout from './separate-check-inout/index.vue'
import SearchBtn from '../search-btn'

import { get_field_settings, PACKAGE_TRAIN_HOTEL_IDS } from 'src/composables/utils/forms'

export const get_default_expedia = (params) => {
  const { t } = useI18n()
  const { props, rules, traveler_limits, date_format, handle_swap_airport, payload , handle_validate_place} = params
  const settings = props.setting?.meta?.default?.fields ?? {}

  return computed(() => ({
    fields: [
      {
        id: PACKAGE_TRAIN_HOTEL_IDS.ORIGIN,
        component: AirportPicker,
        hidden: false,
        model: 'payload',
        order: 1,
        bind: {
          product: props.product,
          color: props.color,
          has_code: true,
          cities_with_airports: false,
          direction: settings?.[PACKAGE_TRAIN_HOTEL_IDS.ORIGIN]?.direction,
          place_types: props.place_types,
          validate: handle_validate_place
        },
        events: {
          handle_swap_airport
        },
        style: {
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_TRAIN_HOTEL_IDS.ORIGIN,
          settings: settings,
          default_values: {
            dimension: {
              columns: {
                desktop: 6,
                tablet: 6,
                mobile: 12
              }
            }
          }
        })
      },
      {
        id: PACKAGE_TRAIN_HOTEL_IDS.FLIGHT_DATES,
        component: DatePicker,
        hidden: false,
        model: 'train_dates',
        order: 2,
        bind: {
          range: true,
          dense: true,
          outlined: true,
          'hide-bottom-space': true,
          rules: rules.dates,
          date_format,
          booking_cutoff_days: 0,
          label: t('common.dates'),
        },
        events: {
          handle_swap_airport
        },
        style: {
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_TRAIN_HOTEL_IDS.FLIGHT_DATES,
          settings: settings,
          default_values: {
            dimension: {
              columns: {
                desktop: 3,
                tablet: 6,
                mobile: 12
              }
            }
          }
        })
      },
      {
        id: PACKAGE_TRAIN_HOTEL_IDS.SEARCH_BTN,
        component: SearchBtn,
        hidden: false,
        model: 'payload',
        order: 3,
        events: {},
        bind: {
          label: t('common.search-now'),
          color: props.color,
          rounded: false
        },
        ...get_field_settings({
          field_id: PACKAGE_TRAIN_HOTEL_IDS.SEARCH_BTN,
          settings: settings,
          default_values: {
            dimension: {
              columns: {
                desktop: 3,
                tablet: 6,
                mobile: 12
              }
            }
          }
        })
      },
      {
        id: PACKAGE_TRAIN_HOTEL_IDS.TRAVELERS,
        component: TravelerSelect,
        hidden: false,
        model: 'travelers',
        order: 4,
        bind: {
          dense: true,
          rules: rules.travelers,
          outlined: true,
          'hide-bottom-space': true,
          with_rooms: true,
          max_rooms: 3,
          max_adults: +traveler_limits?.['adult']?.limit,
          max_infants: +traveler_limits?.['infant']?.limit,
          max_children: +traveler_limits?.['child']?.limit,
          traveler_limit_type: props.package?.traveler_limit_type,
          total_traveler_limit: props.package?.total_traveler_limit,
          traveler_types: props.product.traveler_types,
          default_traveler_count: props.package.default_traveler_count,
          label: t('common.travelers')
        },
        events: {
          handle_swap_airport
        },
        style: {
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_TRAIN_HOTEL_IDS.TRAVELERS,
          settings: settings,
          default_values: {
            dimension: {
              columns: {
                desktop: 3,
                tablet: 6,
                mobile: 12
              }
            }
          }
        })
      },
      {
        id: PACKAGE_TRAIN_HOTEL_IDS.STARS,
        component: StarSelect,
        hidden: false,
        model: 'stars',
        order: 5,
        events: {},
        bind: {
          dense: true,
          outlined: true,
          'hide-bottom-space': true,
          'emit-value': true,
          'map-options': true,
          label: t('common.hotel-stars'),
          hide_label_mode: true,
          rounded: false,
          show_icon: true,
          show_label: true,
        },
        style: {
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_TRAIN_HOTEL_IDS.STARS,
          settings: settings,
          default_values: {
            dimension: {
              columns: {
                desktop: 3,
                tablet: 12,
                mobile: 12
              }
            }
          }
        })
      },
      {
        id: PACKAGE_TRAIN_HOTEL_IDS.HOTEL_DATES,
        component: SeparateCheckInout,
        hidden: false,
        model: 'form_data',
        model_path: 'separate',
        order: 7,
        events: {},
        bind: {
          dense: true,
          date_format,
          flight_dates: payload.value.train_dates,
          hotel_dates: payload.value.hotel_dates,
          display_format: "DD/MM/YYYY",
        },
        style: {
          color: props.color
        },
        ...get_field_settings({
          field_id: PACKAGE_TRAIN_HOTEL_IDS.HOTEL_DATES,
          settings: settings,
          default_values: {
            dimension: {
              columns: {
                desktop: 3,
                tablet: 12,
                mobile: 12
              }
            }
          }
        })
      }
    ]
  }))
}
